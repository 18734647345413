import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[appPassword]'
})
export class AppPasswordDirective {

    private _shown = false;

    constructor(private el: ElementRef) {
        this.setup();
    }

    setup() {
        const parent = this.el.nativeElement.parentNode;
        const span = document.createElement('span');
        span.innerHTML = `&nbsp;&nbsp;<i class="material-icons passwordVisibility" style="cursor: pointer;">visibility</i>`;
        span.addEventListener('click', (event) => {
            // alert('you just clicked me, you need to toggle view')
            this.toggle(span);
        });
        parent.appendChild(span);
    }

    toggle(span: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            span.innerHTML = '&nbsp;&nbsp;<i class="material-icons passwordVisibility" style="cursor: pointer;">visibility_off</i>';
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            span.innerHTML = '&nbsp;&nbsp;<i class="material-icons passwordVisibility" style="cursor: pointer;">visibility</i>';
        }
    }
}
