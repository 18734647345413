import {Injectable} from '@angular/core';

@Injectable()
export class UiHelper {
  constructor() {
  }

  autoFocus(element: string) {
    setTimeout(() => document.getElementById(element).focus({preventScroll: false}), 500);
  }
}
