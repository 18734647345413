import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import 'rxjs/add/operator/map';
import {User} from '../_models/user';

@Injectable()
export class AuthenticationService {
  // userLoggedIn : boolean = false;
  user: User;
  decoded: any;
  decodedRes: any;
  decodedUserId: any;
  entreprise: any;

  constructor(private http: HttpClient) {
    // this.entreprise = JSON.parse(localStorage.getItem('entreprise'));
    this.reloadEntrepriseFromLocalStorage();
  }

  userLoggedIn() {
    return !!this.user;
  }

  reloadEntrepriseFromLocalStorage() {
    // console.log("Reload entreprise from LocalStorage")
    this.entreprise = JSON.parse(localStorage.getItem('entreprise'));
    // console.log(JSON.stringify(this.entreprise));

  }

  getLastVersion() {
    return this.http.get('user/getLastVersion').map(
      data => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        return data[0]; // [0] = LYA
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          return null;
          // return false;
        }
      }
    );
  }

  login(username: string, password: string) {

    const body = {
      'user': username,
      'password': password
    };
    /*const proxyparams = {
      "body": JSON.stringify(body),
      "isBase64Encoded": false
    }*/
    return this.http.post('user/login', body)
      .map(data => {
          if (data['error']) {
            console.info(data);
          }
          if (data['loggedin']) {
            // console.info(data);
            const user = new User(username,
              undefined,
              data['token'],
              undefined,
              undefined,
              undefined,
              data['prenom'],
              data['nom'],
              data['ConfigUser1A'], data['entreprise_id'],
              data['Ent_Name'],
              data['ExternalId'],
              data['Config'],
              false,
              data['scopes']
            );

            if (user && user.token) {
              try {
                user.groups = JSON.parse(atob(user.token.split('.')[1]))['res']['scopes'];
              } catch (e) {
                console.info(e);
              }
              this.user = user;
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.tokenUpdated();
            }
            return this.user;

          } else {
            console.info(data);
            return null;
          }
        },
        error => {
          console.info(error);
        }
      );
  }

  checkrenew(user: User) {
    const body = {
      'token': user.token
    };

    return this.http.post('user/jwtcheckrenew', body)
      .map(data => {
        if (data['error'] || !data['token']) {
          user = null;
          this.user = null;
          localStorage.removeItem('currentUser');
          return user;
        } else {
          user.token = data['token'];
          this.user = user;
          this.tokenUpdated();
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      });
  }

  logout() {
    this.user = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('entreprise');
  }

  tokenUpdated() {
    this.decoded = jwt_decode(this.user.token);
    this.decodedRes = this.decoded.res;
    this.decodedUserId = this.decodedRes.userid;
    return this.decoded;
  }

  getEntreprise() {
    return this.http.get('user/entrepriseGet?entrepriseId=' + this.decodedRes.entreprise_id).map(
      data => {
        if (data['error']) {
          console.info(data);
        }
        this.entreprise = data;
        localStorage.setItem('entreprise', JSON.stringify(this.entreprise));

        return (this.entreprise);
      });
  }
}
