// The user service contains a standard set of CRUD methods for managing users via the api

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Config, Entreprise, User} from '../_models';
import {Observable} from 'rxjs/Observable';
import {take} from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {
  }

  /* getAll() {
      return this.http.get('/users').map((response: Response) => response.json());
  }

  getById(_id: string) {
      return this.http.get('/users/' + _id).map((response: Response) => response.json());
  } */

  create(user: User) {
    return this.http.post('user/create', user).map(
      data => {
        if (data['errorMessage']) {
          console.error(data);
          return null;
        }
        return data;
      },
      err => {
        console.error(err);
        return null;
        // return false;
      }
    );

  }

  update(user: User) {
    return this.http.post('user/update', user).map(
      data => {
        return data;
      },
      err => {
        console.error(err);
        return null;
      }
    );

  }

  Archive(user: any) {
    return this.http.post('user/archive', user).map(
      data => {
        if (data['errorMessage']) {
          // TODO
          console.error(data);
          return false;
        }
        return data['user'];
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log(`Backend update returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          console.log(`err: ${JSON.stringify(err)}`);
          console.error(`err: ${err}`);
          return null;
          // return false;
        }
      });
  }

  UnArchive(user: any) {
    return this.http.post('user/unarchive', user).map(
      data => {
        if (data['errorMessage']) {
          // TODO
          console.error(data);
          return false;
        }
        return data['user'];
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log(`Backend update returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          console.log(`err: ${JSON.stringify(err)}`);
          console.error(`err: ${err}`);
          return null;
          // return false;
        }
      });
  }

  get(id) {
    // return this.http.post('/users/register', user);


    // const body = {
    //   "cdId": cdId
    // };

    return this.http.get('user/get?id=' + id).map(
      data => {
        // Read the result field from the JSON response.
        console.log('Backend returned body was: ' + JSON.stringify(data));
        return data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          console.log(`err: ${JSON.stringify(err)}`);
          console.log(`err: ${err}`);
          return null;
          // return false;
        }
      }
    );

  }

  sendCreateEmail(user: User) {
    // return this.http.post('/users/register', user);


    // const body = {
    //   "cdId": cdId
    // };

    return this.http.post('user/userCreateEmail', user).map(
      data => {
        // Read the result field from the JSON response.
        console.log('Backend returned body was: ' + JSON.stringify(data));
        return data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          console.log(`err: ${JSON.stringify(err)}`);
          console.log(`err: ${err}`);
          return null;
          // return false;
        }
      }
    );
  }

  sendUpdateEmail(user: User) {
    // return this.http.post('/users/register', user);


    // const body = {
    //   "cdId": cdId
    // };

    return this.http.post('user/userUpdateEmail', user).map(
      data => {
        // Read the result field from the JSON response.
        console.log('Backend returned body was: ' + JSON.stringify(data));
        return data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          console.log(`err: ${JSON.stringify(err)}`);
          console.log(`err: ${err}`);
          return null;
          // return false;
        }
      }
    );
  }

  getEntreprise(entrepriseId): Observable<Entreprise> {
    return this.http.get('user/entrepriseGet?entrepriseId=' + entrepriseId).map(
      data => {
        const entreprise = new Entreprise(data['entreprise']);
        entreprise.Configs = data['configs'].map(conf => new Config(conf));
        return entreprise;
      },
      err => console.error(err)
    );
  }

  updateEntreprise(entreprise: Entreprise) {
    try {
      const binData = [];
      const entrepriseData = this.http.post('user/entrepriseSet', entreprise).pipe(take(1)).toPromise().then(
        data => {
          if (data['error']) {
            console.error(data);
          }
          return data;
        },
        err => console.error(err)
      );
      for (const confCreate of entreprise.Configs.filter(c => c.Added)) {
        binData.push(this.http.post('user/configSet', {
          entrepriseObject: entreprise,
          conf: confCreate
        }).pipe(take(1)).toPromise().then(
          dataCA => {
            if (dataCA['error']) {
              console.error(dataCA);
            }
            return dataCA;
          },
          err => console.error(err)));
      }
      for (const confUpdate of entreprise.Configs.filter(c => !c.Added && c.Edited && !c.Deleted)) {
        binData.push(this.http.post('user/configUpdate', {
          entrepriseObject: entreprise,
          conf: confUpdate
        }).pipe(take(1)).toPromise().then(
          dataCA => {
            if (dataCA['error']) {
              console.error(dataCA);
            }
            return dataCA;
          },
          err => console.error(err)));
      }
      for (const confDelete of entreprise.Configs.filter(c => c.Deleted)) {
        binData.push(this.http.post('user/configDelete', {
          entrepriseObject: entreprise,
          conf: confDelete
        }).pipe(take(1)).toPromise().then(
          dataCA => {
            if (dataCA['error']) {
              console.error(dataCA);
            }
            return dataCA;
          },
          err => console.error(err)));
      }
      entrepriseData.then(() => {
        for (const data of binData) {
          data.then(() => {
          });
        }
      });
      return {entrepriseData: entrepriseData, confData: binData};
    } catch (e) {
      console.error(e);
    }
  }

  /*update(user: User) {
      return this.http.put('/users/' + user._id, user);
  }

  delete(_id: string) {
      return this.http.delete('/users/' + _id);
  }*/


  generatePassword(length: number, useUpper: boolean, useNumbers: boolean, useSymbols: boolean) {
    event.preventDefault();

    const passwordLength = length; // || 12;
    const addUpper = useUpper; // || true;
    const addNumbers = useNumbers; // || true;
    const addSymbols = useSymbols; // || true;

    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['!', '?', '@'];

    const getRandom = function (array) {
      return array[Math.floor(Math.random() * array.length)];
    };

    let finalCharacters = '';

    if (addUpper) {
      finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    }

    if (addNumbers) {
      finalCharacters = finalCharacters.concat(getRandom(numbers));
    }

    if (addSymbols) {
      finalCharacters = finalCharacters.concat(getRandom(symbols));
    }

    for (let i = 1; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }
    return finalCharacters.split('').sort(function () {
      return 0.5 - Math.random();
    }).join('');
  }

}
