import {Config} from './config';

export class Entreprise {
  public id: number;
  public Name: string;
  public Adresse: string;
  public FQDN: string;
  public Configs: Config[];

  constructor(obj?) {
    Object.assign(this, obj);
  }

}
