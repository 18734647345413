export class User {
  public id: string;
    constructor(
      public username: string,
      public password: string,
      public token?: string,
      public groups?: string,
      public _id?: string,
      public apikey?: string,
      public firstName?: string,
      public lastName?: string,
      public ConfigUser1A?: string,
      public entreprise_id?: string,
      public Ent_Name?: string,
      public ExternalId?: string,
      public Config?: string,
      public Archived?: boolean,
      public scopes?: string
    ) {
      this.id = _id;
    }
}
