// Prod

export const environment = {
  production: true,
  apiUrlUser: 'https://api.d4e.app/produser/',
  apiUrlPointage: 'https://api.d4e.app/prod/',
  apiUrlCounter: 'https://api.d4e.app/prodcounter/',
  apiUrlTeam: 'https://api.d4e.app/prod/',
  apiUrlProject: 'https://api.d4e.app/prod/',
  currentVersion: 'v.2205.2b'
};
