import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import Swal from '../../../assets/js/plugins/sweetalert2';
import {AuthenticationService, UserService} from '../../_services';
import {UiHelper} from '../../_helpers';
import {Config, Entreprise, User} from '../../_models';

declare var $: any;

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit, AfterViewInit {

  newUser: User = new User(undefined, undefined);
  currentUser: User;
  loading = true;
  sendMail = true;
  passwordStrength: number;
  rechercheUser: any;
  HasArchived: boolean;

  users: any[];
  public entreprise: Entreprise;
  public keyConfig: Config;
  public UserExternalRef: boolean;

    constructor(
      private userService: UserService,
      private http: HttpClient,
      public authenticationService: AuthenticationService,
      public ui: UiHelper
    ) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      userService.getEntreprise(this.currentUser.entreprise_id).subscribe(ent => {
        this.entreprise = ent;
        this.keyConfig = new Config({
          ConfigKey: 'User',
          ConfigSubKey: 'UseRef',
          ConfigValue: '1',
          EntrepriseId: this.entreprise.id
        });
        this.UserExternalRef = this.entreprise.Configs.some(c => c.ConfigKey == this.keyConfig.ConfigKey && c.ConfigSubKey == this.keyConfig.ConfigSubKey && c.ConfigValue == this.keyConfig.ConfigValue)  || this.currentUser.groups.includes('all:dev');
      });
    }

    ngOnInit() {
        // this.loading = true;
        this.reloadUsers();
    }

    userCreate(f: NgForm) {

        if (!this.newUser.username.includes(this.authenticationService.entreprise.entreprise.FQDN)) {
            this.newUser.username += '@' + this.authenticationService.entreprise.entreprise.FQDN;
        }
        // console.log("Username=" + this.newUser.username);


        this.userService.create(this.newUser)
            .subscribe(
                data => {
                    if (data['created']) {
                        // this.router.navigate([this.returnUrl]);

                        if (this.sendMail) {

                            this.userService.sendCreateEmail(this.newUser)
                                .subscribe(
                                    _ => {
                                        // console.log("sendCreateEmail OK");

                                        $.notify({
                                            icon: 'add_alert',
                                            message: 'L\'email a été envoyé au nouvel utilisateur.'

                                        }, {
                                            type: 'success',
                                            timer: 4000,
                                            placement: {
                                                from: 'top',
                                                align: 'right'
                                            }
                                        });

                                    },
                                    _ => {
                                        //    this.alertService.error(error);
                                        // console.log("sendCreateEmail KO");
                                        // console.log(error);
                                        // this.loading = false;
                                    });
                        }


                        this.newUser = new User(undefined, undefined);


                        Swal.fire(
                            'Créé !',
                            'L&#039;utilisateur a &eacute;t&eacute; cr&eacute;&eacute;',
                            'success'
                        );
                        f.resetForm();
                        // f.submitted = false
                        this.reloadUsers();

                    } else {
// this.loading = false;
                        // this.wrongCred = true;
                        // this.router.navigate(["login"]);
                        Swal.fire(
                            'Erreur ! - ' + data['reason'],
                            'L&#039;utilisateur n&#039;a pu &ecirc;tre cr&eacute;&eacute;',
                            'error'
                        );
                    }
                },
                _ => {
//    this.alertService.error(error);
                    // console.log(error);
// this.loading = false;
                });

    }

    reloadUsers() {
        this.http.get('user/getUsersList').subscribe(
            data => {
              // console.log('UserCreateComponent-Backend-reloadUsers returned body was: ' + JSON.stringify(data));
              if (data['error']) {
                // TODO
                // this.model.error = data['error']['message'];
              }
              this.users = data['users'].filter((item) => item.prenom != 'Admin');
              this.HasArchived = this.users.filter(u => u.expired == 1).length > 0;
              this.loading = false;
            });
    }

  generatePassword() {
    // length: number, useUpper: boolean, useNumbers: boolean, userSymbols: boolean
    this.newUser.password = this.userService.generatePassword(8 + 2, true, true, false);
  }

  Archive(user: any) {
    this.userService.Archive(user).subscribe(
      data => {
        if (data['error']) {
          // TODO
          console.error(data['error']);
        }
        user.expired = data.expired;
        this.loading = false;
        this.HasArchived = this.users.filter(u => u.expired == 1).length > 0;
      }
    );
  }

  Unarchive(user: any) {
    this.userService.UnArchive(user).subscribe(
      data => {
        if (data['error']) {
          // TODO
          console.error(data['error']);
        }
        user.expired = data.expired;
        this.loading = false;
        this.HasArchived = this.users.filter(u => u.expired == 1).length > 0;
      }
    );
  }

  ngAfterViewInit(): void {
    this.ui.autoFocus('userSearchInput');
  }

  // onPasswordStrengthChanged(strength) {
  //   // console.log('====================================');
  //   console.log('onPasswordStrengthChanged', strength);
  //   // console.log('====================================');
  //   this.passwordStrength = strength;
  // }
}
