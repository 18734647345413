import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {

            const roles = route.data['roles'] as Array<string>;
            let auth = false;

            const currentUserJS = JSON.parse(currentUser);

            for (const role of roles) {
                if (currentUserJS.groups.indexOf(role) > -1) {
                    auth = true;
                }
            }


            if (auth) {
                console.log('Auth Backup OK');
                return true;
            } else {
                console.log('Auth Backup KO');
                this.router.navigate(['/noauth'], {queryParams: {returnUrl: state.url}});
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
