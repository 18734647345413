import {formatDate, registerLocaleData} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {AfterViewInit, Component, OnInit, ViewChildren} from '@angular/core';
import Swal from '../../assets/js/plugins/sweetalert2';
import {DatesHelper, UiHelper} from '../_helpers';
import {User} from '../_models/user';
import {AuthenticationService, HrService} from '../_services';

registerLocaleData(localeFr, 'fr');

declare var $: any;

@Component({
  selector: 'app-hrmanager',
  templateUrl: './hrmanager.component.html',
  styleUrls: ['./hrmanager.component.css']
})
export class HrmanagerComponent implements OnInit, AfterViewInit {

  currentUser: User;
  userId: any;

  loadingTeams = true;
  loadingUsers = true;
  loadingDay = true;
  loadingWeek = true;
  loadingValid = true;
  loadingCompteurs = true;


  @ViewChildren('hrPointProjetLvl1UpdateDayCount') modalProjectSelector: any;
  @ViewChildren('hrPointProjetLvl2ID_DU') modalSubProjectSelector: any;

  isManager = false;

  @ViewChildren('myTeamsVC') myTeamsVC: any;
  myTeams: any;
  selectedTeam: string;
  @ViewChildren('myMembersVC') myMembersVC: any;
  myMembers: any;
  selectedMember: any;
  selectedMemberId: number;

  countersToValidate: any;
  hrCompteurs: any;
  hrCompteursDay: any;
  hrPointages: any;
  hrCompteursDayHours = 0;

  curDate: Date;
  hrPointDate: any;

  // Modal Nouvelle Activite
  modalLoading = false;
  dcCreate: any;
  dcCreateEl: any;
  dcUpdate: any;
  dcUpdateEl: any;
  // pointageCreate: any;
  // Modal + Pointage
  pCreate: any;

  hrPointagesProjectsAllForGestInclA: any;
  hrPointagesProjectsAll: any;
  hrPointagesProjectsAllInclA: any;
  hrPointagesProjects1: any;
  hrPointagesProjects2: any;
  hrPointProjetLvl1: any;
  hrPointProjetLvl2: any;

  dtPickJ: any;
  hrCompteursDayOfWeek: any;
  currentWeekOfYear: number;
  // dateRangeOfWeek:any;
  dateRangeOfWeekFrom: Date;
  dateRangeOfWeekTo: Date;
  weeksValidated: any;

  cfgValidationSemaine: any;
  public toValidate = 0;
  public commHeight = 50;

  public validateInCurrentWeek = false;
  public validateInCurrentMonth = false;
  public currentMonth: string;
  public monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'
  ];
  public MinusTwoMount: Date;
  public loadingProject: boolean;
  private memoryMonthList: string[];
  private memoryWeekList: number[];

  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService,
    private hrService: HrService,
    private datesHelper: DatesHelper,
    public ui: UiHelper
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = authenticationService.decodedUserId;

    const ValidationSemaineConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'ValidationSemaine')[0];
    if (ValidationSemaineConfig != undefined) {
      this.cfgValidationSemaine = ValidationSemaineConfig.ConfigValue;
    } else {
      this.cfgValidationSemaine = 1;
    } // Par défaut : validation de la semaine possible

    this.currentMonth = this.monthNames[new Date().getMonth()];
    this.MinusTwoMount = new Date();
    this.MinusTwoMount.setMonth(new Date().getMonth() - 2);

    this.reloadPointagesProjects();
  }

  ngOnInit() {

    // Prepare Modal Creation Activite (Day Count)
    this.dcCreate = {};
    this.dcCreateEl = document.getElementById('dcDuree');

    this.dcUpdate = {};
    this.dcUpdateEl = document.getElementById('duDuree');

    this.pCreate = {};
    // this.dcUpdateEl = document.getElementById('duDuree');

    this.curDate = new Date();
    this.hrPointDate = formatDate(this.curDate, 'dd.MM.yyyy', 'fr'); // "29.04.2019";
  }

  ngAfterViewInit() {
    this.reloadTeams();
    const tooltips = $('[data-toggle="tooltip"]');
    this.myTeamsVC.changes.subscribe(_ => {
      // console.log('myTeams.changes');
      // console.log("Team : " + this.selectedTeam);
      $('#selTeams').selectpicker().val(this.selectedTeam); // PK ??
      setTimeout(function () {
        $('.selectpicker').selectpicker('refresh');
        tooltips.tooltip({trigger: 'hover'});
        tooltips.on('click', function () {
          $(this).tooltip('dispose');
        });
      }, 0);
    });

    $('.selectpicker').selectpicker();

    this.modalProjectSelector.changes.subscribe(_ => {
      setTimeout(function () {
        $('.projectSelector').selectpicker('refresh');
      }, 0);
    });
    this.modalSubProjectSelector.changes.subscribe(_ => {
      setTimeout(function () {
        $('.subProjectSelector').selectpicker('refresh');
      }, 0);
    });
  }

  valideDate() {
    return (new Date(this.curDate) < this.MinusTwoMount);
  }

  reloadTeams() {
    this.http.get('hr/team/hrTeamGetByAdmin').subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadTeams returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.myTeams = data['teams'];
        this.myTeamsVC = data['teams'];
        // console.log(this.myTeams);

        // Selectionne le premier (Tous ?)
        if (this.myTeams.length > 0) {
          this.selectedTeam = this.myTeams[0].id;
          this.isManager = true;
          this.teamSelected();

        }

        this.loadingTeams = false;
      });
  }

  teamSelected() {
    // console.log("Team selected : " + idTeam);
    this.reloadMembers();
  }

  reloadMembers() {
    this.loadingUsers = true;
    this.http.get('hr/team/hrTeamGetMembers?teamId=' + this.selectedTeam).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadMembers returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.myMembers = data['members'];
        this.myMembersVC = data['members'];
        // console.log(this.myMembers);

        // Selectionne le peremier (Tous ?)
        this.selectedMember = this.myMembers[0];
        this.selectedMemberId = this.selectedMember.id;
        // this.reloadCountersToValidate();
        this.memberSelected();

        this.loadingUsers = false;
      });
  }

  memberSelected() {
    this.loadingDay = true;
    this.loadingValid = true;
    this.loadingWeek = true;
    this.loadingCompteurs = true;
    // console.log("Member selected : " + idMember);
    // this.selectedMember = idMember;
    this.selectedMember = this.myMembers.filter((item) => item.id == this.selectedMemberId)[0];


    this.reloadAllForMember();

    this.dtPickJ = $('.datetimepicker').datetimepicker({
      locale: 'fr-ch',
      format: 'L',
      showTodayButton: false,
      maxDate: $.now(),
      inline: true,
      calendarWeeks: true,
      // defaultDate: 'day',
      // keepOpen: true, // debug
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-calendar',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
      /*,
      beforeShowDay: function(date) {
         var fulldate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
         console.log(fulldate);
         return fulldate;
      } */
    });

    this.dtPickJ.on('dp.change', (e) => {
      this.onPointDate(e.date);
    });
    // this.dtPickJ.setAttribute("style", "z-index:1;")
    this.dtPickJ.on('dp.update', (e) => {
      this.onPointDatepickernav(e);
    });
    // this.refreshHighlightDays(this.curDate);
    this.dateChanged();

  }

  onPointDate(newDate) {
    this.curDate = newDate._d;
    this.dateChanged();
  }

  onPointDatepickernav(e) {

    this.refreshHighlightDays(e.viewDate);
  }

  /*
                         $$$$$$\                               $$\       $$\   $$\ $$\           $$\       $$\ $$\           $$\        $$\     $$$$$$$\
                        $$  __$$\                              $$ |      $$ |  $$ |\__|          $$ |      $$ |\__|          $$ |       $$ |    $$  __$$\
     $$$$$$\   $$$$$$\  $$ /  \__|$$$$$$\   $$$$$$\   $$$$$$$\ $$$$$$$\  $$ |  $$ |$$\  $$$$$$\  $$$$$$$\  $$ |$$\  $$$$$$\  $$$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$\  $$\   $$\  $$$$$$$\
    $$  __$$\ $$  __$$\ $$$$\    $$  __$$\ $$  __$$\ $$  _____|$$  __$$\ $$$$$$$$ |$$ |$$  __$$\ $$  __$$\ $$ |$$ |$$  __$$\ $$  __$$\\_$$  _|  $$ |  $$ | \____$$\ $$ |  $$ |$$  _____|
    $$ |  \__|$$$$$$$$ |$$  _|   $$ |  \__|$$$$$$$$ |\$$$$$$\  $$ |  $$ |$$  __$$ |$$ |$$ /  $$ |$$ |  $$ |$$ |$$ |$$ /  $$ |$$ |  $$ | $$ |    $$ |  $$ | $$$$$$$ |$$ |  $$ |\$$$$$$\
    $$ |      $$   ____|$$ |     $$ |      $$   ____| \____$$\ $$ |  $$ |$$ |  $$ |$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$ |  $$ |$$  __$$ |$$ |  $$ | \____$$\
    $$ |      \$$$$$$$\ $$ |     $$ |      \$$$$$$$\ $$$$$$$  |$$ |  $$ |$$ |  $$ |$$ |\$$$$$$$ |$$ |  $$ |$$ |$$ |\$$$$$$$ |$$ |  $$ | \$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$$$$$$  |
    \__|       \_______|\__|     \__|       \_______|\_______/ \__|  \__|\__|  \__|\__| \____$$ |\__|  \__|\__|\__| \____$$ |\__|  \__|  \____/ \_______/  \_______| \____$$ |\_______/
                                                                                       $$\   $$ |                  $$\   $$ |                                       $$\   $$ |
                                                                                       \$$$$$$  |                  \$$$$$$  |                                       \$$$$$$  |
                                                                                        \______/                    \______/                                         \______/
  */

  refreshHighlightDays(viewDate) {
    // Pour reset classes CSS
    // $('.datetimepicker').data("DateTimePicker").date (new Date(this.curDate));
    $('.datetimepicker').data('DateTimePicker').date(new Date(viewDate));

    let url = 'hr/point/hrGetCounterDayExistingDates?userId=' + this.selectedMember.id + '&month=' + formatDate(viewDate, 'MM', 'fr') + '&year=' + formatDate(viewDate, 'yyyy', 'fr');
    // console.log("refreshHighlightDays") ;
    // console.log(viewDate) ;
    // console.log(url) ;
    this.http.get(url)
      .subscribe(
        data => {
          if (data['error']) {
            // this.model.error = data['error']['message'];
            location.reload(); // recharge la page car surement pb de login -> login
          }

          let dte, classDte;

          for (const entry of data['CounterDayExistingDates']) {
            dte = entry['CounterDayExistingDate'];

            // console.log(dte.substring(0,10)) ;
            classDte = dte.substring(8, 10) + '.' + dte.substring(5, 7) + '.' + dte.substring(0, 4);
            // console.log(classDte + " : " + entry['Calc'] + ", " + entry['Stat']) ;

            const element = $('[data-day="' + classDte + '"]');
            element.addClass('dayHighlight');

            if (entry['Calc'] == 8) {
              element.addClass('dayAlert');
            }
            if (entry['Calc'] == 9) {
              element.addClass('dayAlert');
            }
            if (entry['Stat'] == 'V') {
              element.addClass('dayAlert');
            }
            if (entry['Stat'] == 'L') {
              element.addClass('dayAlert');
            }
          }
        });

    url = 'hr/counter/hrWeeksValidatedGet?UserId=' + this.selectedMember.id + '&Year=' + formatDate(viewDate, 'yyyy', 'fr');
    // console.log(viewDate) ;
    // console.log(url);
    // $('td.validated').removeClass("validated");
    this.http.get(url)
      .subscribe(
        data => {
          if (data['error']) {
            location.reload(); // recharge la page car surement pb de login -> login
          }

          let numW;
          this.weeksValidated = [];

          for (const entry of data['weeksValidated'].filter(w => w.Status == 'V')) {
            numW = entry['Numero'];
            this.weeksValidated.push(numW);
            $('td').filter(function () {
              return $(this).text() == numW;
            }).addClass('validated');
          }
        },
        error => console.error(error));

  }

  dateChanged() {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.reloadCountersDay();
    this.refreshHighlightDays(this.curDate);
    this.currentWeekOfYear = this.datesHelper.getWeek(this.curDate);
    this.dateRangeOfWeekFrom = this.datesHelper.getDateRangeOfWeekFrom(this.currentWeekOfYear, this.curDate);
    this.dateRangeOfWeekTo = this.datesHelper.getDateRangeOfWeekTo(this.currentWeekOfYear, this.curDate);
    this.reloadCountersDayOfWeek();
    this.reloadDateValidation();
    this.reloadCountersToValidate();
    this.currentMonth = this.monthNames[this.curDate.getMonth()];
  }

  /*
                        $$\                           $$\  $$$$$$\  $$\ $$\ $$$$$$$$\                  $$\      $$\                         $$\
                        $$ |                          $$ |$$  __$$\ $$ |$$ |$$  _____|                 $$$\    $$$ |                        $$ |
     $$$$$$\   $$$$$$\  $$ | $$$$$$\   $$$$$$\   $$$$$$$ |$$ /  $$ |$$ |$$ |$$ |    $$$$$$\   $$$$$$\  $$$$\  $$$$ | $$$$$$\  $$$$$$\$$$$\  $$$$$$$\   $$$$$$\   $$$$$$\
    $$  __$$\ $$  __$$\ $$ |$$  __$$\  \____$$\ $$  __$$ |$$$$$$$$ |$$ |$$ |$$$$$\ $$  __$$\ $$  __$$\ $$\$$\$$ $$ |$$  __$$\ $$  _$$  _$$\ $$  __$$\ $$  __$$\ $$  __$$\
    $$ |  \__|$$$$$$$$ |$$ |$$ /  $$ | $$$$$$$ |$$ /  $$ |$$  __$$ |$$ |$$ |$$  __|$$ /  $$ |$$ |  \__|$$ \$$$  $$ |$$$$$$$$ |$$ / $$ / $$ |$$ |  $$ |$$$$$$$$ |$$ |  \__|
    $$ |      $$   ____|$$ |$$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |$$ |   $$ |  $$ |$$ |      $$ |\$  /$$ |$$   ____|$$ | $$ | $$ |$$ |  $$ |$$   ____|$$ |
    $$ |      \$$$$$$$\ $$ |\$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$ |  $$ |$$ |$$ |$$ |   \$$$$$$  |$$ |      $$ | \_/ $$ |\$$$$$$$\ $$ | $$ | $$ |$$$$$$$  |\$$$$$$$\ $$ |
    \__|       \_______|\__| \______/  \_______| \_______|\__|  \__|\__|\__|\__|    \______/ \__|      \__|     \__| \_______|\__| \__| \__|\_______/  \_______|\__|*/

  reloadAllForMember() {
    // TODO Config
    if (this.selectedMember.ConfigUser1A == 'Emb') {
      this.selectedMember.ConfigUser1ADecoded = 'Embauché';
    } else if (this.selectedMember.ConfigUser1A == 'Temp') {
      this.selectedMember.ConfigUser1ADecoded = 'Temporaire';
    } else {
      this.selectedMember.ConfigUser1ADecoded = 'Non défini';
    }

    this.reloadPointagesProjects();
    this.reloadPointage();
    // this.reloadCountersDayOfWeek();
  }

  reloadPointage() {
    this.reloadCountersToValidate();
    this.reloadCompteurs();
    this.reloadCountersDay();
    const element = $('[data-toggle="tooltip"]');
    element.tooltip({trigger: 'hover'});
    element.on('click', function () {
      $(this).tooltip('dispose');
    });
  }


  reloadCountersToValidate() {
    // WS hrCounterDayUserToValidate
    this.http.get('hr/counter/hrCounterDayUserToValidate?userId=' + this.selectedMember.id).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCountersToValidate returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          console.error(data['error']['message']);
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        const tempData = {};
        data['counterday'].forEach(a => {
          const dateGroup = formatDate(Date.parse(a.Date), 'yyyy.MM.dd', 'fr-ch');
          tempData [dateGroup] = tempData [dateGroup] || [];
          tempData [dateGroup].push(a);
        });
        this.countersToValidate = tempData;
        this.toValidate = data['counterday'].length;
        if (this.toValidate > 0) {
          const resultsToDate = data['counterday'].map(date => new Date(date.Date)).filter(date => date.getFullYear() == this.curDate.getFullYear());
          this.memoryMonthList = resultsToDate.map(date => this.monthNames[date.getMonth()]);
          this.memoryWeekList = resultsToDate.map(date => this.datesHelper.getWeek(date));
          this.reloadDateValidation();
        }
        // console.log(this.myMembers);

        this.loadingValid = false;
      });
  }

  refreshSelector() {
  }

  reloadDateValidation() {
    if (this.memoryWeekList != undefined) {
      this.validateInCurrentMonth = this.memoryMonthList.includes(this.currentMonth);
      this.validateInCurrentWeek = this.memoryWeekList.includes(this.currentWeekOfYear);
    }
  }

  reloadCountersLast() {
    // var curDatemysql
    let parameters;

    parameters = '&period=w';  // Last week


    this.http.get('hr/point/hrPointageUserGet?userId=' + this.selectedMember.id + parameters).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCountersLast returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        // this.countersToValidate = data['counterday'];
        this.hrCompteursDay = data['compteursDay'];

        // console.log(this.myMembers);

        this.loadingDay = false;
      });
  }

  reloadCountersDay() { // = reloadPointages

    // var curDatemysql

    let curDatemysql;
    let parameters;
    if (this.curDate) {
      // curDatemysql = this.curDate.getFullYear() + '-' + this.curDate.getMonth() + '-' + this.curDate.getDay();
      curDatemysql = formatDate(this.curDate, 'yyyy-MM-dd', 'fr');
      parameters = '&dateFrom=' + curDatemysql + '&dateTo=' + curDatemysql;
    } else {
      parameters = '&period=d';
    }
    // parameters = '&period=w';  // Last week


    this.http.get('hr/point/hrPointageUserGet?userId=' + this.selectedMember.id + parameters).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCountersLast returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrPointages = data['pointages'];
        this.hrCompteursDay = data['compteursDay'];

        this.hrCompteursDayHours = 0;
        for (let i = 0; i < this.hrCompteursDay.length; i++) {
          this.hrCompteursDayHours += this.hrCompteursDay[i].Hours;
        }

        this.loadingDay = false;


      });
  }

  reloadCountersDayOfWeek() {

    // var curDatemysql
    // var parameters;

    // var curDatemysql
    // var parameters;
    // curDatemysql = this.curDate.getFullYear() + '-' + this.curDate.getMonth() + '-' + this.curDate.getDay();
    // curDatemysql = formatDate(this.curDate, 'yyyy-MM-dd', 'fr');
    // parameters = '&dateFrom='+formatDate(this.dateRangeOfWeekFrom, 'yyyy-MM-dd', 'fr') +
    // '&dateTo='+formatDate(this.dateRangeOfWeekTo, 'yyyy-MM-dd', 'fr');
    this.loadingWeek = true;
    this.hrService.hrCounterDayUserGet(this.selectedMember.id, this.dateRangeOfWeekFrom, this.dateRangeOfWeekTo)
      .subscribe(
        data => {
          const tempData = {};
          data['counterday'].forEach(function (a) {
            const dateGroup = formatDate(Date.parse(a.Date), 'yyyy.MM.dd', 'fr-ch');
            tempData [dateGroup] = tempData [dateGroup] || [];
            tempData [dateGroup].push(a);
          });

          this.hrCompteursDayOfWeek = tempData;
          this.loadingWeek = false;
          // console.info('hrCompteursDayOfWeek = ' + this.hrCompteursDayOfWeek);
          // For test //
          /*for (let i = 0; i < this.hrCompteursDayOfWeek.length; i++) {
            console.log(this.hrCompteursDayOfWeek[i]);
          }*/
          ///////////
        },
        _ => {
          // this.alertService.error(error);
          // console.log(error);
          location.reload(); // recharge la page car surement pb de login -> login
        });
  }

  updateConfigU1A(idUser) {
    Swal.fire({
      title: 'Indiquer le statut :',
      input: 'select',
      // inputValue: this.project.StatAllocated/8,
      inputOptions: {'Emb': 'Embauché', 'Temp': 'Temporaire'},
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Modifier',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: (val) => {
        this.selectedMember.ConfigUser1A = val;
        const body = {
          UserId: idUser,
          NewStatus: val
        };
        // console.log(`body: ${JSON.stringify(body)}`);
        this.http.post('user/userStatusUpdate', body).subscribe(
          data => {
            return data;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);
              Swal.showValidationMessage(
                `Request failed: ${err}`
              );
              location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
              location.reload(); // recharge la page car surement pb de login -> login
            }
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {

      this.reloadAllForMember();
      if (result.value) {
        // this.validCountersAll();
        // this.getProject();
        Swal.fire(
          'Enregistré !',
          'Le statut est enregistré !',
          'success'
        );
      }
    });
  }

  reloadCompteurs() {
    // this.http.get( 'hr/point/hrPointageUserGet?userId='+this.userId+'&period=d').subscribe(
    this.http.get('hr/point/hrPointageCompteursUserGet?userId=' + this.selectedMember.id).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCompteurs returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrCompteurs = data['compteurs'];
        this.loadingCompteurs = false;

        setTimeout(function () {
          const element = $('[data-toggle="tooltip"]');
          element.tooltip({trigger: 'hover'});
          element.on('click', function () {
            $(this).tooltip('dispose');
          });
        });

      });
  }

  reloadPointagesProjects() {
    this.loadingProject = true;
    this.http.get('hr/project/hrProjectsParentForGuest?userId=' + this.selectedMemberId).subscribe(
      data => {
        // console.log('AccueilComponent-Backend-reloadPointagesProjects returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        // this.hrPointagesProjectsAll = data['projects'];
        this.hrPointagesProjects1 = data['project'].filter((item) => item.Type == 'P');
        this.loadChildren(this.selectedMemberId).then(_ => this.loadingProject = false);
      });
  }

  async loadChildren(userId) {
    this.http.get('hr/project/hrProjectsChildrensForGuest?userId=' + userId).subscribe(
      data => {
        if (data['errorMessage']) {
          console.error(data);
        } else {
          try {
            const subProject = data['project'];
            this.hrPointagesProjects1.forEach(p => p.SubProjectList = subProject.filter(pp => pp.parentId == p.id));
            if (this.hrPointProjetLvl1 != null) {
              this.hrPointProjetLvl1 = this.hrPointagesProjects1.filter(p => p.id == this.hrPointProjetLvl1.id)[0];
              if (this.hrPointProjetLvl2 != null) {
                this.hrPointProjetLvl2 = this.hrPointProjetLvl1.SubProjectList.filter(p => p.id == this.hrPointProjetLvl2.id)[0];
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      });
  }

  // Copie de accueil
  addActivProject1Selected() {
    this.dcCreate.project1 = this.hrPointProjetLvl1.SubProjectList[0];
  }

  addActivProject2Selected() {
    // console.log("Project 2 selected : " + idProject2);
    // this.hrPointProjetLvl2 = null;
    // this.hrPointProjetLvl2 = idProject2;

    this.http.get('hr/point/hrPointageProjectsGet?ProjectId=' + this.hrPointProjetLvl2).subscribe(
      data => {
        // console.log('HrManager-Backend returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        // let body = JSON.parse(data['body']);
        this.dcCreate.project2 = data['projects'][0];
        // this.hrCompteursDay = data['compteursDay'];
        // this.hrPointageRunning = data['running'];
        // this.loading = false;
      }
    );
  }

  /*
          $$\                      $$$$$$\                                 $$\      $$$$$$\                                 $$\
          $$ |                    $$  __$$\                                $$ |    $$  __$$\                                $$ |
     $$$$$$$ | $$$$$$\  $$\   $$\ $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\   $$ /  \__| $$$$$$\   $$$$$$\   $$$$$$\ $$$$$$\    $$$$$$\
    $$  __$$ | \____$$\ $$ |  $$ |$$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$ |      $$  __$$\ $$  __$$\  \____$$\\_$$  _|  $$  __$$\
    $$ /  $$ | $$$$$$$ |$$ |  $$ |$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$ |      $$ |  \__|$$$$$$$$ | $$$$$$$ | $$ |    $$$$$$$$ |
    $$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$ |  $$\ $$ |      $$   ____|$$  __$$ | $$ |$$\ $$   ____|
    \$$$$$$$ |\$$$$$$$ |\$$$$$$$ |\$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$  |$$ |      \$$$$$$$\ \$$$$$$$ | \$$$$  |\$$$$$$$\
     \_______| \_______| \____$$ | \______/  \______/  \______/ \__|  \__|  \____/  \______/ \__|       \_______| \_______|  \____/  \_______|
                        $$\   $$ |
                        \$$$$$$  |
                         \______/
  */


  dayCountCreate() {
    let pDateTo = null;
    if (this.dcCreate.dcDateTo != undefined && this.dcCreate.dcDateTo.length > 0) {
      pDateTo = formatDate(this.dcCreate.dcDateTo, 'yyyy/MM/dd', 'fr');
    }

    const body = {
      'userId': this.selectedMemberId,
      'date': formatDate(this.curDate, 'yyyy/MM/dd', 'fr'),
      'dateTo': pDateTo,
      'hours': this.dcCreate.dcDuree,
      'source': 'M',
      'project1': this.hrPointProjetLvl1.id,
      'project2': this.hrPointProjetLvl2.id,
      'comment': this.dcCreate.dcComm
    };
    this.http.post('hr/counter/hrCounterDayAdd', body).subscribe(
      _ => {
        $('#createDayCountModal').modal('hide');
        this.reloadCountersDayOfWeek();
        this.reloadDateValidation();
        this.reloadCountersToValidate();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );

  }


  /*
                        $$\            $$\                                    $$$$$$\                                 $$\
                        \__|           $$ |                                  $$  __$$\                                $$ |
     $$$$$$\   $$$$$$\  $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\   $$$$$$\  $$ /  \__| $$$$$$\   $$$$$$\   $$$$$$\ $$$$$$\    $$$$$$\
    $$  __$$\ $$  __$$\ $$ |$$  __$$\\_$$  _|   \____$$\ $$  __$$\ $$  __$$\ $$ |      $$  __$$\ $$  __$$\  \____$$\\_$$  _|  $$  __$$\
    $$ /  $$ |$$ /  $$ |$$ |$$ |  $$ | $$ |     $$$$$$$ |$$ /  $$ |$$$$$$$$ |$$ |      $$ |  \__|$$$$$$$$ | $$$$$$$ | $$ |    $$$$$$$$ |
    $$ |  $$ |$$ |  $$ |$$ |$$ |  $$ | $$ |$$\ $$  __$$ |$$ |  $$ |$$   ____|$$ |  $$\ $$ |      $$   ____|$$  __$$ | $$ |$$\ $$   ____|
    $$$$$$$  |\$$$$$$  |$$ |$$ |  $$ | \$$$$  |\$$$$$$$ |\$$$$$$$ |\$$$$$$$\ \$$$$$$  |$$ |      \$$$$$$$\ \$$$$$$$ | \$$$$  |\$$$$$$$\
    $$  ____/  \______/ \__|\__|  \__|  \____/  \_______| \____$$ | \_______| \______/ \__|       \_______| \_______|  \____/  \_______|
    $$ |                                                 $$\   $$ |
    $$ |                                                 \$$$$$$  |
    \__|                                                  \______/
  */

  pointageCreate() {
    $('#createPointageModal').modal('hide');

    const body = {
      'userId': this.selectedMemberId,
      'date': formatDate(this.curDate, 'yyyy/MM/dd', 'fr'),
      'time': this.pCreate.pHeure,
      'datetime': formatDate(this.curDate, 'yyyy/MM/dd', 'fr') + ' ' + this.pCreate.pHeure,
      'source': 'W',
      'project1': this.hrPointProjetLvl1.id,
      'project2': this.hrPointProjetLvl2.id
    };
    // console.log('hrPointage');
    // console.log('body: ' + JSON.stringify(body));


    this.http.post('hr/point/hrPointage', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // this.ec2_desktop_state = data;
        // this.loading = false;
        // if (data['error']) {
        // TODO
        // }
        this.reloadPointage();
        this.refreshHighlightDays(this.curDate);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );

  }

  /*
                        $$\ $$\       $$\  $$$$$$\                                 $$\
                        $$ |\__|      $$ |$$  __$$\                                $$ |
    $$\    $$\ $$$$$$\  $$ |$$\  $$$$$$$ |$$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\
    \$$\  $$  |\____$$\ $$ |$$ |$$  __$$ |$$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\
     \$$\$$  / $$$$$$$ |$$ |$$ |$$ /  $$ |$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|
      \$$$  / $$  __$$ |$$ |$$ |$$ |  $$ |$$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |
       \$  /  \$$$$$$$ |$$ |$$ |\$$$$$$$ |\$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |
        \_/    \_______|\__|\__| \_______| \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|

  */


  validCounter(cdId) {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.loadingValid = true;

    const body = {
      'cdId': cdId
    };

    this.http.post('hr/counter/hrCounterDayValidate', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        this.memberSelected();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
        }
      }
    );
  }

  /*
                         $$$$$$\                                 $$$$$$\                                 $$\
                        $$  __$$\                               $$  __$$\                                $$ |
     $$$$$$\   $$$$$$\  $$ /  \__|$$\   $$\  $$$$$$$\  $$$$$$\  $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\
    $$  __$$\ $$  __$$\ $$$$\     $$ |  $$ |$$  _____|$$  __$$\ $$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\
    $$ |  \__|$$$$$$$$ |$$  _|    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|
    $$ |      $$   ____|$$ |      $$ |  $$ | \____$$\ $$   ____|$$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |
    $$ |      \$$$$$$$\ $$ |      \$$$$$$  |$$$$$$$  |\$$$$$$$\ \$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |
    \__|       \_______|\__|       \______/ \_______/  \_______| \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|



  */


  refuseCounter(cdId) {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.loadingValid = true;

    const body = {
      'cdId': cdId
    };

    this.http.post('hr/counter/hrCounterDayRefuse', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        this.memberSelected();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*
                         $$$$$$\                                $$$$$$$\            $$\            $$\
                        $$  __$$\                               $$  __$$\           \__|           $$ |
     $$$$$$\   $$$$$$\  $$ /  \__|$$\   $$\  $$$$$$$\  $$$$$$\  $$ |  $$ | $$$$$$\  $$\ $$$$$$$\ $$$$$$\
    $$  __$$\ $$  __$$\ $$$$\     $$ |  $$ |$$  _____|$$  __$$\ $$$$$$$  |$$  __$$\ $$ |$$  __$$\\_$$  _|
    $$ |  \__|$$$$$$$$ |$$  _|    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$  ____/ $$ /  $$ |$$ |$$ |  $$ | $$ |
    $$ |      $$   ____|$$ |      $$ |  $$ | \____$$\ $$   ____|$$ |      $$ |  $$ |$$ |$$ |  $$ | $$ |$$\
    $$ |      \$$$$$$$\ $$ |      \$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      \$$$$$$  |$$ |$$ |  $$ | \$$$$  |
    \__|       \_______|\__|       \______/ \_______/  \_______|\__|       \______/ \__|\__|  \__|  \____/

  */
  refusePoint(pId) {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.loadingValid = true;

    const body = {
      'id': pId,
      'userId': this.selectedMember.id
    };

    this.http.post('hr/point/hrPointageDelete', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        this.memberSelected();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*
                        $$\ $$\       $$\            $$\               $$$$$$$\            $$\            $$\
                        $$ |\__|      $$ |           $$ |              $$  __$$\           \__|           $$ |
    $$\    $$\ $$$$$$\  $$ |$$\  $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\  $$ |  $$ | $$$$$$\  $$\ $$$$$$$\ $$$$$$\
    \$$\  $$  |\____$$\ $$ |$$ |$$  __$$ | \____$$\\_$$  _|  $$  __$$\ $$$$$$$  |$$  __$$\ $$ |$$  __$$\\_$$  _|
     \$$\$$  / $$$$$$$ |$$ |$$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$  ____/ $$ /  $$ |$$ |$$ |  $$ | $$ |
      \$$$  / $$  __$$ |$$ |$$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |      $$ |  $$ |$$ |$$ |  $$ | $$ |$$\
       \$  /  \$$$$$$$ |$$ |$$ |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\ $$ |      \$$$$$$  |$$ |$$ |  $$ | \$$$$  |
        \_/    \_______|\__|\__| \_______| \_______|  \____/  \_______|\__|       \______/ \__|\__|  \__|  \____/



  */


  validatePoint(pId) {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.loadingValid = true;

    const body = {
      'id': pId,
      'userId': this.selectedMember.id
    };

    this.http.post('hr/point/hrPointageValidate', body).subscribe(
      data => {
        // Read the result field from the JSON response.
        // console.log('Backend hrPointageValidate returned body was: ' + JSON.stringify(data));
        this.memberSelected();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('hrPointageValidate An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*
                              $$\            $$\
                              $$ |           $$ |
    $$\   $$\  $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\
    $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\
    $$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |
    $$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|
    \$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\
     \______/ $$  ____/  \_______| \_______|  \____/  \_______|
              $$ |
              $$ |
              \__|
  */

  openUpdateCounterModal(cd) {
    this.curDate = cd.Date;
    this.dcUpdate.dcDuree = cd.Hours;
    this.dcUpdate.id = cd.id;
    this.dcUpdate.Comment = cd.Comment;

    this.hrPointProjetLvl1 = this.hrPointagesProjects1.filter(p => p.id == cd.Project1Id)[0];

    try {
      this.hrPointProjetLvl2 = this.hrPointProjetLvl1.SubProjectList.filter(p => p.id == cd.Project2Id)[0];
    } catch (e) {
      console.error(e);
    }
    $('#hrPointProjetLvl1UpdateDayCount').selectpicker().val(cd.Project1Id);
    $('#hrPointProjetLvl2ID_DU').selectpicker().val(cd.Project2Id);
    setTimeout(function () {
      $('#hrPointProjetLvl1UpdateDayCount').selectpicker('refresh');
      $('#hrPointProjetLvl2ID_DU').selectpicker('refresh');
    }, 0);

    $('#updateDayCountModal').modal('show');
  }

  dayCountUpdate() {
    const body = {
      'userId': this.selectedMemberId,
      'id': this.dcUpdate.id,
      'hours': this.dcUpdate.dcDuree,
      'comment': this.dcUpdate.Comment,
      'source': 'M',
      'project1': this.hrPointProjetLvl1.id,
      'project2': this.hrPointProjetLvl2.id
    };

    this.http.post('hr/counter/hrCounterDayUpdate', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // this.ec2_desktop_state = data;
        // this.loading = false;
        // if (data['error']) {
        // TODO
        // }
        $('#updateDayCountModal').modal('hide');
        this.reloadCountersDayOfWeek();
        this.reloadDateValidation();
        this.reloadCountersToValidate();
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }

  ModalSelectedProjectChange() {
    try {
      this.hrPointProjetLvl2 = this.hrPointProjetLvl1.SubProjectList[0];
    } catch (e) {
      console.error(e);
    }
  }

  confirmAllAct() {
    Swal({
      title: 'Tout valider',
      text: 'Voulez-vous vraiment valider toutes les activités en attente de validation pour cette personne ?',
      type: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Oui, Tout valider',
      cancelButtonText: 'Annuler'
    })
      .then((result) => {
        if (result.value) {
          this.validCountersAll();
          Swal.fire(
            'Validé !',
            'Les activités ont été validées',
            'success'
          );
        }
      });
  }

  public validCountersAll() {
    this.loadingWeek = true;
    this.loadingDay = true;
    this.loadingValid = true;

    const body = {
      'userId': this.selectedMemberId
    };

    this.http.post('hr/counter/hrCounterDayValidateAllForUser', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        this.memberSelected();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  public WeekValidate() {
    const currentWeek = this.currentWeekOfYear;

    Swal({
      title: 'Tout valider',
      text: 'Voulez-vous vraiment valider toutes les activités en attente de validation pour cette personne à la semaine ' + currentWeek + ' ?',
      type: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Oui, Tout valider',
      cancelButtonText: 'Annuler'
    })
      .then((result) => {
        if (result.value) {
          this.ValidateAllOfWeek(this.currentWeekOfYear);
          Swal.fire(
            'Validé !',
            'Les activités ont été validées',
            'success'
          );
        }
      });
  }

  public MonthValidate() {

    Swal({
      title: 'Tout valider',
      text: 'Voulez-vous vraiment valider toutes les activités en attente de validation pour cette personne pour le mois ' + this.currentMonth + ' ?',
      type: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Oui, Tout valider',
      cancelButtonText: 'Annuler'
    })
      .then((result) => {
        if (result.value) {
          this.ValidateAllOfMonth(this.currentMonth);
          Swal.fire(
            'Validé !',
            'Les activités ont été validées',
            'success'
          );
        }
      });
  }

  public autoResize() {
    this.commHeight = $('#dcComm')[0].scrollHeight;
  }

  public setDate(date: Date) {
    this.curDate = new Date(date);
    this.dateChanged();
  }

  weekUnvalidate() {
    const body = {
      'UserId': this.selectedMemberId,
      'Year': formatDate(this.curDate, 'yyyy', 'fr'),
      'Week': this.currentWeekOfYear
    };

    Swal.fire({
      title: 'Invalider la semaine ?',
      text: 'Voulez-vous invalider cette semaine ? L\'employé pourra de nouveau modifier ses activités sur cette semaine.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Invalider !',
      cancelButtonText: 'Non, Laisser validé',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        this.http.post('hr/counter/hrWeeksUnvalidate', body).subscribe(
          _ => {
            // Read the result field from the JSON response.
            this.refreshHighlightDays(this.curDate);
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:');
              console.error(err.error);
              // location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // location.reload(); // recharge la page car surement pb de login -> login
            }
          });
      }
    });
  }

  private ValidateAllOfWeek(currentWeekOfYear: number) {
    this.http.get('hr/counter/hrCounterDayUserToValidate?userId=' + this.selectedMember.id).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCountersToValidate returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        const resultsToDate = data['counterday'].filter(date => new Date(date.Date).getFullYear() == this.curDate.getFullYear()).map(date => {
          return [{
            'id': date.id,
            'ToValidation': this.datesHelper.getWeek(new Date(date.Date)) == currentWeekOfYear
          }][0];
        });
        resultsToDate.forEach(element => {
          if (element.ToValidation) {
            this.validCounter(element.id);
          }
        });
        this.reloadCountersToValidate();
      });
  }

  /*
 /$$      /$$                     /$$       /$$   /$$           /$$    /$$          /$$ /$$       /$$             /$$
| $$  /$ | $$                    | $$      | $$  | $$          | $$   | $$         | $$|__/      | $$            | $$
| $$ /$$$| $$  /$$$$$$   /$$$$$$ | $$   /$$| $$  | $$ /$$$$$$$ | $$   | $$ /$$$$$$ | $$ /$$  /$$$$$$$  /$$$$$$  /$$$$$$    /$$$$$$
| $$/$$ $$ $$ /$$__  $$ /$$__  $$| $$  /$$/| $$  | $$| $$__  $$|  $$ / $$/|____  $$| $$| $$ /$$__  $$ |____  $$|_  $$_/   /$$__  $$
| $$$$_  $$$$| $$$$$$$$| $$$$$$$$| $$$$$$/ | $$  | $$| $$  \ $$ \  $$ $$/  /$$$$$$$| $$| $$| $$  | $$  /$$$$$$$  | $$    | $$$$$$$$
| $$$/ \  $$$| $$_____/| $$_____/| $$_  $$ | $$  | $$| $$  | $$  \  $$$/  /$$__  $$| $$| $$| $$  | $$ /$$__  $$  | $$ /$$| $$_____/
| $$/   \  $$|  $$$$$$$|  $$$$$$$| $$ \  $$|  $$$$$$/| $$  | $$   \  $/  |  $$$$$$$| $$| $$|  $$$$$$$|  $$$$$$$  |  $$$$/|  $$$$$$$
|__/     \__/ \_______/ \_______/|__/  \__/ \______/ |__/  |__/    \_/    \_______/|__/|__/ \_______/ \_______/   \___/   \_______/
*/

  private ValidateAllOfMonth(currentMonth: string) {
    this.http.get('hr/counter/hrCounterDayUserToValidate?userId=' + this.selectedMember.id).subscribe(
      data => {
        // console.log('HrmanagerComponent-Backend-reloadCountersToValidate returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        const resultsToDate = data['counterday'].filter(date => new Date(date.Date).getFullYear() == this.curDate.getFullYear()).map(date => {
          return [{
            'id': date.id,
            'ToValidation': this.monthNames[new Date(date.Date).getMonth()] == currentMonth
          }][0];
        });
        resultsToDate.forEach(element => {
            if (element.ToValidation) {
              this.validCounter(element.id);
            }
          }
        );
        this.reloadCountersToValidate();
      });
  }
}
