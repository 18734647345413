import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../_services';
import {UiHelper} from '../../_helpers';

declare var $: any;

@Component({
    selector: 'app-teams-list',
    templateUrl: './teams-list.component.html',
    styleUrls: ['./teams-list.component.css']
})
export class TeamsListComponent implements OnInit {

    loading: boolean;

    teams: any;
    teamCreateName: any;

    constructor(
      private http: HttpClient,
      public authenticationService: AuthenticationService,
      public ui: UiHelper
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.reloadTeams();
    }

    reloadTeams() {
        this.http.get('hr/team/hrTeamsGet').subscribe(
            data => {
                // console.log('TeamsListComponent-Backend-reloadTeams returned body was: ' + JSON.stringify(data));
                if (data['error']) {
                    // TODO
                    // this.model.error = data['error']['message'];
                }
                this.teams = data['teams'];

                this.loading = false;
            });
    }

    teamCreate() {
        this.loading = true;
        this.http.post('hr/team/hrTeamCreate', { 'TeamName': this.teamCreateName }).subscribe(
            _ => {
                $('#createTeamModal').modal('hide');
                this.teamCreateName = '';
                this.reloadTeams();
            },
            (err: HttpErrorResponse) => {
                if (err.error instanceof Error) {
                    // console.log('An error occurred:', err.error.message);
                } else {
                    // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
                    // console.log(`err: ${JSON.stringify(err)}`);
                    // console.log(`err: ${err}`);
                }
                location.reload(); // recharge la page car surement pb de login -> login
            });
    }

  Archive(id) {
    console.log('Archive asked for : ' + id);
    this.loading = true;
    this.http.post('hr/team/hrTeamArchive', { 'TeamId': id }).subscribe(
      data => {
        this.reloadTeams();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        location.reload();
        // console.error(err);
      });
  }

  Unarchive(id) {
    console.log('Unarchive asked for : ' + id);
    this.loading = true;
    this.http.post('hr/team/hrTeamUnarchive', { 'TeamId': id }).subscribe(
      data => {
        this.reloadTeams();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        location.reload();
        // console.error(err);
      });
  }


}
