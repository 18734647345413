import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'projects'})
export class ProjectsPipe implements PipeTransform {
  transform(projects: string, projectList: any): string {

    const projectsArray = projects.split(',');
    const projects1 = projectsArray[0];
    const projects2 = projectsArray[1];

    let projectsLib = 'Chargement...';
    if (projects1 != 'null' && projectList != undefined) {
      let projectSelected;
      const projectsFtr1 = projectList.filter(p => projects1 == p.id);
      if (projectsFtr1.length > 0) {
        projectSelected = projectsFtr1[0];
        projectsLib = projectsFtr1[0].Project;

        if (projects2 != 'null' && projectSelected.SubProjectList != undefined) {
          projectsLib += ' / ';

          const projectsFtr2 = projectSelected.SubProjectList.filter(p => projects2 == p.id);
          if (projectsFtr2.length > 0) {
            projectsLib += projectsFtr2[0].Project;
          } else {
            projectsLib += 'Chargement...';
          }
        }
      }
    }
    return projectsLib;
  }
}
