import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AfterViewInit, Component, EventEmitter, OnInit, ViewChildren} from '@angular/core';
import Swal from '../../assets/js/plugins/sweetalert2';
import {Config, Entreprise, User} from '../_models';
import {AuthenticationService, UserService} from '../_services';

declare var $: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, AfterViewInit {

  public currentUser: User;
  public userId: any;

  public loading: boolean;
  public ArchivedLoading = true;
  public AllLoading = true;

  public cfgHoursDivided = false;

  @ViewChildren('projects') hrPointagesProjectsAll: any;
  public hrPointagesProjects1: any;
  public hrPointagesProjects2: any;
  public hrPointagesProjectsTeams: any;
  public rechercheprojets: any;
  public eventReload: EventEmitter<String> = new EventEmitter();

  public projectNameCreate: string;
  public hrAllProjects: any;
  public hrProjectsArchived: any;
  public hrProjectsActive: any;
  public entreprise: Entreprise;
  public keyConfig: Config;
  public OdooLock = false;


  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService,
    public userService: UserService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = authenticationService.decodedUserId;

    userService.getEntreprise(this.currentUser.entreprise_id).subscribe(ent => {
      this.entreprise = ent;
      this.keyConfig = new Config({
        ConfigKey: 'Lya2Odoo',
        ConfigSubKey: 'ProjectCreateLock',
        ConfigValue: '1',
        EntrepriseId: this.entreprise.id
      });
      this.OdooLock = this.entreprise.Configs.some(c => c.ConfigKey == this.keyConfig.ConfigKey && c.ConfigSubKey == this.keyConfig.ConfigSubKey && c.ConfigValue == this.keyConfig.ConfigValue) && !this.currentUser.groups.includes('all:dev');
    });
  }


  ngOnInit() {
    this.loading = true;
    this.reloadProjects();

    this.cfgHoursDivided = localStorage.getItem('cfgHoursDivided') == '1';
  }

  ngAfterViewInit() {
    this.hrPointagesProjectsAll.changes.subscribe(_ => this.ngRendered());
    this.autoFocus('searchProjectInput');
  }

  ngRendered() {
    const element = $('[data-toggle="tooltip"]');
    element.tooltip({trigger: 'hover'});
    element.on('click', function () {
      $(this).tooltip('dispose');
    });
  }

  loadActiveProject() {
    this.http.get('hr/project/hrParentProjectsDetail?actif=P').subscribe(
      data => {
        if (data['error']) {
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrProjectsActive = data['projects'];
        this.hrProjectsActive.sort((a, b) => a.Project.localeCompare(b.Project, 'fr', {'sensitivity': 'base'}));
        let i = -1;
        this.hrProjectsActive.forEach(p => {
          if (p.Teams != null) {
            p['teamList'] = p.Teams.split(',').map(s => {
              i++;
              return {
                'Nom': s,
                'id': p.TeamsId.split(',')[i]
              };
            });
            i = -1;
          }
        });
        this.loading = false;
        this.reloadAllProject();
      }, error => console.error(error));
  }

  async loadArchivedProject() {
    this.http.get('hr/project/hrParentProjectsDetail?archived=A').subscribe(
      async data => {
        if (data['error']) location.reload(); // recharge la page car surement pb de login -> login
        this.hrProjectsArchived = data['projects'];
        this.hrProjectsArchived.sort((a, b) => a.Project.localeCompare(b.Project, 'fr', {'sensitivity': 'base'}));
        let i = -1;
        this.hrProjectsArchived.forEach(p => {
          if (p.Teams != null) {
            p['teamList'] = p.Teams.split(',').map(s => {
              i++;
              return {
                'Nom': s,
                'id': p.TeamsId.split(',')[i]
              };
            });
            i = -1;
          }
        });
        this.ArchivedLoading = false;
        this.reloadAllProject();
      }, error => console.error(error));
  }

  reloadAllProject() {
    if (this.hrProjectsActive !== undefined && this.hrProjectsArchived !== undefined) {
      this.hrAllProjects = this.hrProjectsActive.concat(this.hrProjectsArchived).sort((a, b) => a.Project.localeCompare(b.Project, 'fr', {'sensitivity': 'base'}));
      this.AllLoading = false;
    }
  }

  reloadProjects() {
    this.loadActiveProject();
    this.loadArchivedProject();
  }

  projectCreate() {
    this.loading = true;
    const body = {
      'ProjectName': this.projectNameCreate
    };
    // console.log('body: ' + JSON.stringify(body));

    this.http.post('hr/point/hrPointageProjectsCreate', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        $('#createProjectModal').modal('hide');
        this.reloadProjects();
        this.projectNameCreate = '';
        // this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
        }
        location.reload(); // recharge la page car surement pb de login -> login
      }
    );
    // this.loading = false;
  }

  hrProjectArchiveDel(projectId) {
    this.loading = true;
    const body = {
      'projectId': projectId
    };
    // console.log('body: ' + JSON.stringify(body));

    Swal.fire({
      title: 'Supprimer/Archiver ?',
      text: 'Voulez-vous supprimer (ou archiver) ce projet ? ' +
        'Pour qu\'il soit effectivement supprimé, il ne doit plus contenir de sous-projet, et aucune activité ni pointage ne doit avoir eu lieu dessus.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Supprimer/Archiver !',
      cancelButtonText: 'Non, Annuler',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.http.post('hr/point/hrPointageProjectsArchiveDelete', body).subscribe(
          data => {
            // console.log('Backend returned body was: ' + JSON.stringify(data));
            // $('#createProjectModal').modal('hide');
            this.eventReload.emit();
            this.reloadProjects();
            // this.projectNameCreate = "";
            this.loading = false;

            // var jsonData = JSON.stringify(data);

            if (data[0].deleted_row_count == 1) {
              Swal.fire(
                'Supprimé !',
                'Le projet était vide, et a donc été supprimé.',
                'success'
              );
            } else {
              Swal.fire(
                'Archivé !',
                'Le projet n\'était pas vide ou a été utilisé, et a donc été simplement archivé.',
                'success'
              );

            }

          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
            }
            location.reload(); // recharge la page car surement pb de login -> login
          }
        );
      } else {
        this.loading = false;
      }
    });
    // this.loading = false;
  }

  hrProjectUnArchive(projectId) {
    this.loading = true;
    const body = {
      'projectId': projectId
    };
    // console.log('body: ' + JSON.stringify(body));

    this.http.post('hr/point/hrPointageProjectsUnArchive', body).subscribe(
      data => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // $('#createProjectModal').modal('hide');
        this.eventReload.emit();
        this.reloadProjects();
        // this.projectNameCreate = "";
        this.loading = false;

        // var jsonData = JSON.stringify(data);

        if (data[0].updated_row_count > 0) {
          Swal.fire(
            'Restauré !',
            'Le projet a été restauré.',
            'success'
          );

        }

      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
        }
        location.reload(); // recharge la page car surement pb de login -> login
      }
    );
    // this.loading = false;
    // this.loading = false;
  }

  cfgHoursDividedToggle() {
    this.cfgHoursDivided = !this.cfgHoursDivided;
    localStorage.setItem('cfgHoursDivided', this.cfgHoursDivided ? '1' : '0');
    // console.log('cfgHoursDivided:', this.cfgHoursDivided);
  }

  autoFocus(element: string) {
    setTimeout(() => document.getElementById(element).focus({preventScroll: false}), 500);
  }
}
