import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../_services';
import {UiHelper} from '../../_helpers';

@Component({
  selector: 'app-teams-edit',
  templateUrl: './teams-edit.component.html',
  styleUrls: ['./teams-edit.component.css']
})
export class TeamsEditComponent implements OnInit {

  id: number;
  team: any;

  loading: boolean;

  AllUsers: any;
  gestionnaires: any;
  AllTeams: any;
  EditMode = false;


  constructor(public authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private http: HttpClient,
              public ui: UiHelper) {

    this.team = {};
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getTeam();
      this.getUsers();
    });
  }

  getTeam(): void {
    this.getTeams();
  }

  /*
                         $$\  $$$$$$$$\
                         $$ | \__$$  __|
     $$$$$$\   $$$$$$\ $$$$$$\   $$ | $$$$$$\   $$$$$$\  $$$$$$\$$$$\   $$$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$ |$$  __$$\  \____$$\ $$  _$$  _$$\ $$  _____|
    $$ /  $$ |$$$$$$$$ | $$ |    $$ |$$$$$$$$ | $$$$$$$ |$$ / $$ / $$ |\$$$$$$\
    $$ |  $$ |$$   ____| $$ |$$\ $$ |$$   ____|$$  __$$ |$$ | $$ | $$ | \____$$\
    \$$$$$$$ |\$$$$$$$\  \$$$$  |$$ |\$$$$$$$\ \$$$$$$$ |$$ | $$ | $$ |$$$$$$$  |
     \____$$ | \_______|  \____/ \__| \_______| \_______|\__| \__| \__|\_______/
    $$\   $$ |
    \$$$$$$  |
     \______/
  */


  getTeams(): void {
    // this.loadingTeams = true;

    this.http.get('hr/team/hrTeamsGet').subscribe(
      data => {
        // console.log('TeamsEditComponent-Backend-hrTeamsGet returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
        }
        this.AllTeams = data['teams'];
        this.team = this.AllTeams.filter((item) => item.id == this.id)[0];
        // this.loadingTeams = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  /*
                         $$\     $$\   $$\
                         $$ |    $$ |  $$ |
     $$$$$$\   $$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\   $$$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$ |  $$ |$$  _____|$$  __$$\ $$  __$$\ $$  _____|
    $$ /  $$ |$$$$$$$$ | $$ |    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|\$$$$$$\
    $$ |  $$ |$$   ____| $$ |$$\ $$ |  $$ | \____$$\ $$   ____|$$ |       \____$$\
    \$$$$$$$ |\$$$$$$$\  \$$$$  |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |
     \____$$ | \_______|  \____/  \______/ \_______/  \_______|\__|      \_______/
    $$\   $$ |
    \$$$$$$  |
     \______/
  */

  getUsers(): void {
    // this.loadingUsers = true;

    this.http.get('hr/team/hrTeamGetUsersWithProjectInfo?teamId=' + this.id).subscribe(
      data => {
        // console.log('ProjectEditComponent-Backend-hrTeamGetUsersWithProjectInfo returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
        }
        this.AllUsers = data['members'];
        // console.log(JSON.stringify(this.AllUsers));
        this.gestionnaires = this.AllUsers.filter((item) => item.scopes != null && item.scopes.includes('hr:manager'));
        // this.membres       = this.AllUsers // .filter((item) => item.Member == 1);
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  /*
    $$\      $$\                         $$\                         $$$$$$\        $$$$$$$$\
    $$$\    $$$ |                        $$ |                        \_$$  _|       \__$$  __|
    $$$$\  $$$$ | $$$$$$\  $$$$$$\$$$$\  $$$$$$$\   $$$$$$\   $$$$$$\  $$ |  $$$$$$$\  $$ | $$$$$$\   $$$$$$\  $$$$$$\$$$$\
    $$\$$\$$ $$ |$$  __$$\ $$  _$$  _$$\ $$  __$$\ $$  __$$\ $$  __$$\ $$ |  $$  __$$\ $$ |$$  __$$\  \____$$\ $$  _$$  _$$\
    $$ \$$$  $$ |$$$$$$$$ |$$ / $$ / $$ |$$ |  $$ |$$$$$$$$ |$$ |  \__|$$ |  $$ |  $$ |$$ |$$$$$$$$ | $$$$$$$ |$$ / $$ / $$ |
    $$ |\$  /$$ |$$   ____|$$ | $$ | $$ |$$ |  $$ |$$   ____|$$ |      $$ |  $$ |  $$ |$$ |$$   ____|$$  __$$ |$$ | $$ | $$ |
    $$ | \_/ $$ |\$$$$$$$\ $$ | $$ | $$ |$$$$$$$  |\$$$$$$$\ $$ |    $$$$$$\ $$ |  $$ |$$ |\$$$$$$$\ \$$$$$$$ |$$ | $$ | $$ |
    \__|     \__| \_______|\__| \__| \__|\_______/  \_______|\__|    \______|\__|  \__|\__| \_______| \_______|\__| \__| \__|



  */

  MemberInTeam(userid, oldState) {
    // this.loadingUsers = true;
    // alert('InTeam ' + projectid +  " : " + oldState);

    const body = {
      'TeamId': this.id,
      'UserId': userid,
      'Add': oldState == null ? 1 : 0
    };

    this.http.post('hr/team/hrMemberInTeam', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // $('#createProjectModal').modal('hide');
        this.getUsers();
        // this.projectNameCreate = "";
        // this.loadingUsers = false;

        // var jsonData = JSON.stringify(data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*
     $$$$$$\        $$\               $$\           $$$$$$\        $$$$$$$$\
    $$  __$$\       $$ |              \__|          \_$$  _|       \__$$  __|
    $$ /  $$ | $$$$$$$ |$$$$$$\$$$$\  $$\ $$$$$$$\    $$ |  $$$$$$$\  $$ | $$$$$$\   $$$$$$\  $$$$$$\$$$$\
    $$$$$$$$ |$$  __$$ |$$  _$$  _$$\ $$ |$$  __$$\   $$ |  $$  __$$\ $$ |$$  __$$\  \____$$\ $$  _$$  _$$\
    $$  __$$ |$$ /  $$ |$$ / $$ / $$ |$$ |$$ |  $$ |  $$ |  $$ |  $$ |$$ |$$$$$$$$ | $$$$$$$ |$$ / $$ / $$ |
    $$ |  $$ |$$ |  $$ |$$ | $$ | $$ |$$ |$$ |  $$ |  $$ |  $$ |  $$ |$$ |$$   ____|$$  __$$ |$$ | $$ | $$ |
    $$ |  $$ |\$$$$$$$ |$$ | $$ | $$ |$$ |$$ |  $$ |$$$$$$\ $$ |  $$ |$$ |\$$$$$$$\ \$$$$$$$ |$$ | $$ | $$ |
    \__|  \__| \_______|\__| \__| \__|\__|\__|  \__|\______|\__|  \__|\__| \_______| \_______|\__| \__| \__|



  */

  AdminInTeam(userid, oldState) {
    // this.loadingUsers = true;
    // alert('InTeam ' + projectid +  " : " + oldState);

    const body = {
      'TeamId': this.id,
      'UserId': userid,
      'Add': oldState == null ? 1 : 0
    };

    this.http.post('hr/team/hrAdminInTeam', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // $('#createProjectModal').modal('hide');
        this.getUsers();
        // this.projectNameCreate = "";
        // this.loadingUsers = false;

        // var jsonData = JSON.stringify(data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  SwitchEditMode() {
    this.EditMode = true;
  }

  UpdateName() {


    this.http.post('hr/team/hrTeamUpdate', {'TeamName': this.team.Name, 'TeamId': this.team.id}).subscribe(
      data => {
        if (data['error']) {
        }
        this.EditMode = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error(err.error);
          // location.reload(); // recharge la page car surement pb de login -> login
        } else {
          console.error(err.error);
          // location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }
}
