import {User} from './user';

export class Project {
  public id = -1;
  public Project = '';
  public ExternalId = '';
  public SubProject = '';
  public Time = 0.0;
  public TeamList: any[];
  public Teams = '';
  public TeamsId = '';
  public Type = '';
  public parentId = -1;
  public User: User[];
  public StatAllocated = 0;

  public Added = false;
  public Deleted = false;
  public Edited = false;

  constructor(obj?) {
    Object.assign(this, obj);
    this.Added = obj == undefined;
  }
}
