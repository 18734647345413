import {formatDate} from '@angular/common';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import DataTable from '../../assets/js/plugins/datatables.min';
import {HrService} from '../_services';

declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, AfterViewInit {

  loading = true;
  data: any;
  table: DataTable;
  filteredTable: any;
  dtData: any;

  showDetailed = true;

  perStart: string;
  perStop: string;
  perStartDate: Date;
  perStopDate: Date;
  filterOnlyCounted = false;
  TotalHeures: number;
  TotalRoundHeures: any;
  TotalHeures2: any;
  filterOn = false;
  deltaTimePreset = [];
  deltaTimePresetSelected: any;

  constructor(private hrService: HrService) {
    [
      {title: 'Aujourd\'hui', start: new Date(), end: new Date()},
      {title: 'Hier', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: new Date(new Date().setDate(new Date().getDate() - 1))},
      {title: 'Ce mois', start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date()},
      {title: 'Le mois dernier', start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: '6 derniers mois', start: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: '12 derniers mois', start: new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: 'Cette année', start: new Date(new Date().getFullYear(), 0, 1), end: new Date()},
      {title: 'L\'année précédente', start: new Date(new Date().getFullYear() - 1, 0, 1), end: new Date(new Date().getFullYear() - 1, 12, 0)},
    ].forEach(e => this.deltaTimePreset.push(e));
    this.deltaTimePresetSelected = this.deltaTimePreset[0];
  }

  ngOnInit() {

    this.perStopDate = this.deltaTimePresetSelected.end;
    this.perStartDate = this.deltaTimePresetSelected.start;
    this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
    this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
    // console.log(this.perStopDate);
    $('#report thead tr').clone(true).appendTo('#report thead');

    this.loadData();

    // DataTable
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
  }

  /*
    $$\                           $$\ $$$$$$$\             $$\
    $$ |                          $$ |$$  __$$\            $$ |
    $$ | $$$$$$\   $$$$$$\   $$$$$$$ |$$ |  $$ | $$$$$$\ $$$$$$\    $$$$$$\
    $$ |$$  __$$\  \____$$\ $$  __$$ |$$ |  $$ | \____$$\\_$$  _|   \____$$\
    $$ |$$ /  $$ | $$$$$$$ |$$ /  $$ |$$ |  $$ | $$$$$$$ | $$ |     $$$$$$$ |
    $$ |$$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$  __$$ |
    $$ |\$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$$$$$$  |\$$$$$$$ | \$$$$  |\$$$$$$$ |
    \__| \______/  \_______| \_______|\_______/  \_______|  \____/  \_______|



  */

  loadData() {

    this.hrService.hrCounterDayGet(this.perStartDate, this.perStopDate)
      .subscribe(
        data => {
          this.data = data['counterday'];
          // this.dtData;
          // this.table.ajax = this.data;

          this.dtData = [];

          for (let i = 0; i < this.data.length; i++) {
            try {
              this.dtData.push([this.data[i].Emp,
                this.data[i].prenom,
                this.data[i].nom,
                this.data[i].Date,
                this.data[i].Hours,
                this.data[i].HoursRound,
                // RoundHourToUpperQuarter(this.data[i].Hours, 3),
                this.data[i].Project1,
                this.data[i].ExternalId1,
                this.data[i].Project2,
                this.data[i].ExternalId2,
                this.data[i].Comment,
                this.data[i].Source,
                this.data[i].Week,
                this.data[i].WeekStatus]);
              // console.log('Heure normale : ' + this.data[i].Hours + "\nHeure arrondie : " +
              // RoundHourToUpperQuarter(this.data[i].Hours, 3));
            } catch (exception) {
              console.error(exception);
            }

          }
          // console.log( this.dtData );
          // this.TotalHeures = null;
          // this.TotalHeures = this.table.column(4).data().toArray().reduce((sum, current) => sum + current, 0);
          // this.table = $('#report').DataTable(
          // {
          //   destroy: true,
          //   data: this.data
          // });
          this.DTInit();

          this.loading = false;
        },
        _ => {
          // console.log(error);
        });

  }

  /*
                                  $$\           $$$$$$$$\ $$\ $$\   $$\
                                  $$ |          $$  _____|\__|$$ |  $$ |
     $$$$$$\   $$$$$$\   $$$$$$\  $$ |$$\   $$\ $$ |      $$\ $$ |$$$$$$\    $$$$$$\   $$$$$$\   $$$$$$$\
     \____$$\ $$  __$$\ $$  __$$\ $$ |$$ |  $$ |$$$$$\    $$ |$$ |\_$$  _|  $$  __$$\ $$  __$$\ $$  _____|
     $$$$$$$ |$$ /  $$ |$$ /  $$ |$$ |$$ |  $$ |$$  __|   $$ |$$ |  $$ |    $$$$$$$$ |$$ |  \__|\$$$$$$\
    $$  __$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |  $$ |$$ |      $$ |$$ |  $$ |$$\ $$   ____|$$ |       \____$$\
    \$$$$$$$ |$$$$$$$  |$$$$$$$  |$$ |\$$$$$$$ |$$ |      $$ |$$ |  \$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |
     \_______|$$  ____/ $$  ____/ \__| \____$$ |\__|      \__|\__|   \____/  \_______|\__|      \_______/
              $$ |      $$ |          $$\   $$ |
              $$ |      $$ |          \$$$$$$  |
              \__|      \__|           \______/
  */


  applyFilters() {
    this.loading = true;

    this.perStartDate = new Date(this.perStart);
    this.perStopDate = new Date(this.perStop);
    this.filterOn = true;

    this.loadData();
  }

  /*
    $$$$$$$\ $$$$$$$$\ $$$$$$\           $$\   $$\
    $$  __$$\\__$$  __|\_$$  _|          \__|  $$ |
    $$ |  $$ |  $$ |     $$ |  $$$$$$$\  $$\ $$$$$$\
    $$ |  $$ |  $$ |     $$ |  $$  __$$\ $$ |\_$$  _|
    $$ |  $$ |  $$ |     $$ |  $$ |  $$ |$$ |  $$ |
    $$ |  $$ |  $$ |     $$ |  $$ |  $$ |$$ |  $$ |$$\
    $$$$$$$  |  $$ |   $$$$$$\ $$ |  $$ |$$ |  \$$$$  |
    \_______/   \__|   \______|\__|  \__|\__|   \____/



  */


  DTInit() {
    // console.log('Période ' + this.perStart + ' - ' + this.perStop + '\n');
    const currentdate = new Date();
    if (this.filterOn) {
      this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
      this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
    }

    const datetime = currentdate.toLocaleDateString('fr-CH') + ' ' + currentdate.toLocaleTimeString('fr-CH');
    // console.log('Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
    // formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '\n');

    let messageTop = 'Export détaillé des activités\n\n';
    messageTop += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '\n';
    if (this.filterOnlyCounted) {
      messageTop += 'Seulement les activités comptabilisées';
    }
    const messageBottom = '\n\n\n(c) D4E - Digital4Efficiency ' + currentdate.getFullYear() + '\n' + datetime;

    let messageTopExcel = 'Export détaillé des activités - ';
    messageTopExcel += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '\n';
    if (this.filterOnlyCounted) {
      messageTopExcel += ' - Seulement les activités comptabilisées';
    }
    const messageBottomExcel = '(c) D4E - Digital4Efficiency ' + currentdate.getFullYear() + ' - ' + datetime;

    let messageTopHtml = '<h3>Export détaillé des activités</h3><br/><br/>';
    messageTopHtml += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '<br/>';
    if (this.filterOnlyCounted) {
      messageTopHtml += 'Seulement les activités comptabilisées<br/>';
    }
    const messageBottomHtml = '<br/><br/>' + datetime + '<br/>&copy; D4E - Digital4Efficiency ' + currentdate.getFullYear();


    this.table = $('#report').DataTable(
      {
        destroy: true,
        orderCellsTop: true,
        // rowGroup: {
        //   startRender: function ( rows, group, level ) {
        //     // return group +' ('+rows.count()+' rows)';
        //     return '<tr><td colspan="3">'+group+'</td><td></td><td>25</td><td></td><td></td><td></td><td></td><td></td></tr>'
        //   }
        // },
        // responsive: true,
        // fixedHeader: true,
        'language': {
          'sProcessing': 'Traitement en cours...',
          'sSearch': 'Rechercher&nbsp;:',
          'sLengthMenu': 'Afficher _MENU_ éléments',
          'sInfo': 'Affichage des éléments _START_ &agrave; _END_ sur _TOTAL_ éléments',
          'sInfoEmpty': 'Affichage de l\'élément 0 &agrave; 0 sur 0 élément',
          'sInfoFiltered': '(filtré de _MAX_ éléments au total)',
          'sInfoPostFix': '',
          'sLoadingRecords': 'Chargement en cours...',
          'sZeroRecords': 'Aucun élément &agrave; afficher',
          'sEmptyTable': 'Aucune donnée disponible dans le tableau',
          'oPaginate': {
            'sFirst': 'Premier',
            'sPrevious': 'Précédent',
            'sNext': 'Suivant',
            'sLast': 'Dernier'
          },
          'oAria': {
            'sSortAscending': ': activer pour trier la colonne par ordre croissant',
            'sSortDescending': ': activer pour trier la colonne par ordre décroissant'
          },
          'select': {
            'rows': {
              _: '%d lignes sélectionnées',
              0: 'Aucune ligne sélectionnée',
              1: '1 ligne sélectionnée'
            }
          },
          'buttons': {
            'pageLength': {
              _: 'Afficher %d éléments <i class="material-icons">keyboard_arrow_down</i>',
              '-1': 'Afficher TOUT <i class="material-icons">keyboard_arrow_down</i>'
            }
          }
        },
        data: this.dtData,
        // data: [[18,"2019-03-21T00:00:00.000Z",0.71,null,null,null,null,"P"],[19,"2019-03-26T00:00:00.000Z",0.07,null,null,null,null,"P"]],
        // columns: [
        //   // CD.id, CD.Date, CD.Hours, P1.Project Project1, P2.Project Project2, CD.Project1Id, CD.Project2Id, CD.Source
        //     { title: "Identifiant" },
        //     { title: "Prénom" },
        //     { title: "Nom" },
        //     { title: "Date" },
        //     { title: "Heures" },
        //     { title: "Projet" },
        //     { title: "Sous-Project" },
        //     { title: "Source" }
        // ],
        'columnDefs': [
          {
            'render': function (data, type, row) {
              function pad(s) {
                return (s < 10) ? '0' + s : s;
              }

              const d = new Date(data);
              return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
            },
            'targets': 3
          },
          {
            'render': function (data, type, row) {
              const source = data;
              let typeLib: string;
              if (source == 'W') {
                typeLib = 'Pointage Web';
              } else if (source == 'P') {
                typeLib = 'Pointage';
              } else if (source == 'L') {
                typeLib = 'Manuel (à valider)';
              } else if (source == 'M') {
                typeLib = 'Manuel (validé)';
              } else if (source == 'N') {
                typeLib = 'Manuel (refusé)';
              } else {
                typeLib = 'Inconnue : ' + source;
              }

              return typeLib;
            },
            'targets': 11
          },
          {
            'render': function (data, type, row) {
              // var source=data;
              return data == 'V' ? 'Validée' : '';
            },
            'targets': 13
          },
          // { "visible": false,  "targets": [ 3 ] }
        ],
        'footerCallback': function (row, data, start, end, display) {
          const api = this.api();

          // Total over all pages
          this.TotalHeures = Math.round(
            (api
                .column(4, {page: 'all', search: 'applied', order: 'current'})
                .data()
                .reduce(function (a, b) {
                  return a + b;
                }, 0)
              + Number.EPSILON) * 100) / 100;
          this.TotalHeuresRound = api
            .column(5, {page: 'all', search: 'applied', order: 'current'})
            .data()
            .reduce(function (a, b) {
              return a + b;
            }, 0);

          // Update footer
          $(api.column(4).footer()).html(
            this.TotalHeures
          );
          $(api.column(5).footer()).html(
            this.TotalHeuresRound
          );
          // console.info(api
          //  .column( 4, { page: 'all', search: 'applied', order: 'current'} )
          //  .data());
          // console.log(this.TotalHeures);
        },

        'initComplete': function () {/*
        this.api()
          .columns()
          .nodes()
          .filter(d => d.every(val => val.innerText.length === 0)) // All columns empty ||| innerText = valeur du champ
          .each(index => {
            this.api().column(index).visible(false);
          });*/
        },
        stateSave: false,
        colReorder: false, // TODO bug refresh
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'Tout']],
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'copy',
            text: 'Copier les données'
          },
          {
            extend: 'csv',
            text: 'Exporter en CSV'
          },
          {
            extend: 'excel',
            text: 'Exporter vers Excel',
            messageTop: messageTopExcel,
            messageBottom: messageBottomExcel,
            sheetName: 'D4E LYA'
          },
          {
            extend: 'pdf',
            text: 'Générer un PDF',
            messageTop: messageTop,
            messageBottom: messageBottom
          },
          {
            extend: 'print',
            text: 'Imprimer',
            messageTop: messageTopHtml,
            messageBottom: messageBottomHtml
          },
          'pageLength'
          // {
          //   extend: 'pageLength',
          //   // text: {'-1': 'Afficher tout', _: 'Afficher %d éléments'}
          // }
        ]
      });
    // this.table.colReorder.reset();
    const mytab = this.table;
    // $('.dt-button-collection').removeClass('dropdown-menu');


    // console.log(this.table.column(4).data().toArray().reduce((sum, current) => sum + current, 0));
    this.TotalHeures = Math.round(((this.table.column(4).data().toArray().reduce((sum, current) => sum + current, 0)) +
      Number.EPSILON) * 100) / 100;
    // console.log(this.TotalHeures);
    this.filteredTable = this.table.rows({search: 'applied'});
    // console.log("filteredTable : " + this.filteredTable.toArray());

    this.TotalHeures2 = this.filteredTable.column(4).data().toArray().reduce((sum, current) => sum + current, 0);
    this.TotalRoundHeures = this.table.column(5).data().toArray().reduce((sum, current) => sum + current, 0);
    // console.log("this.filterOnlyCounted : " + this.filterOnlyCounted);
    if (this.filterOnlyCounted) {
      // console.log("this.filterOnlyCounted Filtre");
      this.table
        .column(11) // Source
        .search(' ');
      this.table
        .column(11) // Source
        .search('validé|Pointage', true, false, false) // search( input [, regex[ , smart[ , caseInsen ]]] )
        .draw();
      const element = $('input[placeholder="Source"]');
      element[0].value = '';
      element.prop('disabled', true);

    } else {
      this.table
        .column(11) // Source
        .search('')
        .draw();
      $('input[placeholder="Source"]').prop('disabled', false);
    }


    this.restoreBSClasses();

    // $('#report thead tr').clone(true).appendTo( '#report thead' );
    $('#report thead tr:eq(1) th').each(function (i) {
      // $(this).html( '<input type="text" style="width:100%" placeholder="'+title+'" />' );

      $('input', this).on('keyup change clear', function () {
        if (mytab.column(i).search() !== this.value) {
          mytab
            .column(i)
            .search(this.value)
            .draw();
          // console.log(mytab);
        }
      });
    });

    // var table = $('#example').DataTable();

    // this.table.on( 'buttons-action', function ( e, buttonApi, dataTable, node, config ) {
    this.table.on('draw', function () {
      // console.log( 'Button '+buttonApi.text()+' was activated' );
      // console.log( 'Button page was activated' );
      // console.log(report.table.column(4).data().toArray().reduce((sum, current) => sum + current, 0));
      // report.TotalHeures = null;
      // report.TotalHeures = report.table.column(4).data().toArray().reduce((sum, current) => sum + current, 0);
      // this.restoreBSClasses();
      // $('.dt-button').addClass('btn').addClass('btn-primary').removeClass('dt-button');
      $('.paginate_button').addClass('btn').addClass('btn-primary').removeClass('paginate_button');
      $('.current').removeClass('btn-primary');
    });
  }

  restoreBSClasses() {
    $('.dt-button').addClass('btn').addClass('btn-primary').removeClass('dt-button');
    $('.paginate_button').addClass('btn').addClass('btn-primary').removeClass('paginate_button');
    $('.current').removeClass('btn-primary');
  }

  SelectedTimeChange() {
    this.perStopDate = this.deltaTimePresetSelected.end;
    this.perStartDate = this.deltaTimePresetSelected.start;
    this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
    this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
  }
}
