import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../_models/project';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = 'hr/project/';
  }

  create(project: Project) {
    const body = {
      'project1': project.id,
      'ProjectName': project.Project
    };
    return this.post('hrPointageProjectsCreate', body);
  }

  public get(path: string) {
    return this.http.get(this.baseUrl + path);
  }

  public post(path: string, body: any) {
    return this.http.post(this.baseUrl + path, body);
  }

  delete(project: Project) {
    return this.post('hrPointageProjectsArchiveDelete', {'projectId': project.id});
  }

  unArchive(project: Project) {
    return this.post('hrPointageProjectsUnArchive', {'projectId': project.id});
  }

  async update(project: Project) {

  }

  updateDayAllocated(project: Project, day: number) {
    project.StatAllocated = day * 8;
    return this.updateTimeAllocated(project);
  }

  updateTimeAllocated(project: Project) {
    const body = {
      ProjectId: project.id,
      StatAllocated: project.StatAllocated
    };
    return this.post('hrProjectStatAllocatedUpdate', body);
  }

  getInfo(id: number): Observable<Project> {
    return this.get(id + '/detail').pipe(map(p => {
      // console.info(p);
      return new Project(p['projects'][0]);
    }));
  }

  getSubInfo(id: number): Observable<Project> {
    return this.get('hrSubProjectsDetail/' + id).pipe(map((p: Project) => new Project(p)));
  }

  getAll(): Observable<Project[]> {
    return this.get('hrProjectsParentAll').pipe(map((p: Project[]) => p.map(pp => new Project(pp))));
  }

  getAllProjectForGuest(): Observable<Project[]> {
    return this.get('hrProjectsParentForGuest').pipe(map((p: Project[]) => p.map(pp => new Project(pp))));
  }

  getSubProject(project: Project): Observable<Project[]> {
    return this.get('getSubProjects/' + project.id).pipe(map((p: Project[]) => {
      // console.info(p['projects']);
      return p['projects'].map(pp => new Project(pp));
    }));
  }

  getAllSubProjectForGuest(): Observable<Project[]> {
    return this.get('hrProjectsChildrensForGuest').pipe(map((p: Project[]) => p.map(pp => new Project(pp))));
  }
}
