import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'hrPointSource'})
export class HrPointSourcePipe implements PipeTransform {
  transform(source: any): string {

    let typeLib = source + '?';

    // switch (type) {
    //   case "1":
    //     typeLib = 'Prés.';
    //     break;

    //   default:
    //     typeLib = '? -' + type + '.';
    //     break;
    // }

    switch (source) {
      case 'W':
        typeLib = 'Pointage';
        break;
      case 'V':
        typeLib = 'Pointage à valider';
        break;
      case 'X':
        typeLib = 'Pointage ajouté à valider';
        break;
      case 'U':
        typeLib = 'Pointage supprimé';
        break;
      case 'P':
        typeLib = 'Pointage';
        break;
      case 'L':
        typeLib = 'Manuel (à valider)';
        break;
      case 'M':
        typeLib = 'Manuel (validé)';
        break;
      case 'N':
        typeLib = 'Manuel (refusé)';
        break;
    }  // Activité
    return typeLib;
  }
}
