import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from '../../../assets/js/plugins/sweetalert2';
import {User} from '../../_models/user';
import {AuthenticationService, UserService} from '../../_services';
import {ProjectsComponent} from '../../projects/projects.component';

declare var $: any;

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  id: string;
  user = new User(undefined, undefined);
  userUpd = new User(undefined, undefined);
  loading = true;

  sendMail = false;
  hrPointagesProjectsAll: any;
  hrPointagesProjects1: any;
  hrPointagesProjects2: any;
  AllTeams: any;
  loadingTeams: boolean;
  cfgHoursDivided: boolean;
  HaveProjectScope = false;
  HaveAdminScope = false;
  HaveSuperAdminScope = false;
  HaveManagerScope = false;
  HavepointReportScope = false;
  projectsRef: ProjectsComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    public authenticationService: AuthenticationService
  ) {
    this.projectsRef = new ProjectsComponent(http, authenticationService, userService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getUser();
      this.reloadProjects1();
      // this.reloadProjects2();
      this.getTeams();
      this.cfgHoursDivided = localStorage.getItem('cfgHoursDivided') == '1';

      this.projectsRef.eventReload.subscribe(() => {
        this.reloadProjects1();
      });
    });
  }

  /*
                         $$\     $$\   $$\
                         $$ |    $$ |  $$ |
     $$$$$$\   $$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$ |  $$ |$$  _____|$$  __$$\ $$  __$$\
    $$ /  $$ |$$$$$$$$ | $$ |    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|
    $$ |  $$ |$$   ____| $$ |$$\ $$ |  $$ | \____$$\ $$   ____|$$ |
    \$$$$$$$ |\$$$$$$$\  \$$$$  |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |
     \____$$ | \_______|  \____/  \______/ \_______/  \_______|\__|
    $$\   $$ |
    \$$$$$$  |
     \______/
  */

  getUser(): void {
    // this.loading = true;

    this.http.get('user/get?userId=' + this.id).subscribe(
      data => {
        // console.log('UpdateComponent-Backend-Get returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          console.error(data);
        }
        this.user = data['user'];
        this.user._id = this.id;
        this.userUpd = this.user;
        this.parseScope();
        this.loading = false;

      });
  }

  parseScope() {
    if (this.userUpd.scopes != undefined) {
      this.HaveProjectScope = this.userUpd.scopes.includes('hr:projets');
      this.HaveAdminScope = JSON.parse(this.userUpd.scopes).filter(e => e == 'all:admin').length > 0;
      this.HaveSuperAdminScope = this.userUpd.scopes.includes('all:admin-ent');
      this.HaveManagerScope = this.userUpd.scopes.includes('hr:manager');
      this.HavepointReportScope = this.userUpd.scopes.includes('pointage:report');
    } else {
      this.userUpd.scopes = '[]';
    }
  }

  switchAdmin() {
    const scope = 'all:admin';
    if (this.HaveAdminScope) {
      this.removeScope(scope);
    } else {
      this.addScope(scope);
    }
  }

  switchProject() {
    const scope = 'hr:projets';
    if (this.HaveProjectScope) {
      this.removeScope(scope);
    } else {
      this.addScope(scope);
    }
  }

  switchBigAdmin() {
    const scope = 'all:admin-ent';
    if (this.HaveSuperAdminScope) {
      this.removeScope(scope);
    } else {
      this.addScope(scope);
    }
  }

  switchManager() {
    const scope = 'hr:manager';
    if (this.HaveManagerScope) {
      this.removeScope(scope);
    } else {
      this.addScope(scope);
    }
  }

  switchpointReport() {
    const scope = 'pointage:report';
    if (this.HavepointReportScope) {
      this.removeScope(scope);
    } else {
      this.addScope(scope);
    }
  }

  addScope(newScope: string) {
    const scopes = JSON.parse(this.userUpd.scopes);
    scopes.push(newScope);
    this.userUpd.scopes = JSON.stringify(scopes);
    this.parseScope();
  }

  removeScope(oldScope: string) {
    this.userUpd.scopes = JSON.stringify(JSON.parse(this.userUpd.scopes).filter(s => s != oldScope));
    this.parseScope();
  }

  /*                                        $$\   $$\                 $$\            $$\
                                            $$ |  $$ |                $$ |           $$ |
    $$\   $$\  $$$$$$$\  $$$$$$\   $$$$$$\  $$ |  $$ | $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\
    $$ |  $$ |$$  _____|$$  __$$\ $$  __$$\ $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\
    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|$$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |
    $$ |  $$ | \____$$\ $$   ____|$$ |      $$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|
    \$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      \$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\
     \______/ \_______/  \_______|\__|       \______/ $$  ____/  \_______| \_______|  \____/  \_______|
                                                      $$ |
                                                      $$ |
                                                      \__|*/


  userUpdate() {
    this.loading = true;
    this.userService.update(this.userUpd)
      .subscribe(
        data => {
          if (data['updated']) {
            if (this.sendMail) {

              this.userService.sendUpdateEmail(this.userUpd)
                .subscribe(
                  _ => {
                    // console.log("sendCreateEmail OK");

                    $.notify({
                      icon: 'add_alert',
                      message: 'L\'email a été envoyé à l\'utilisateur.'
                    }, {
                      type: 'success',
                      timer: 4000,
                      placement: {
                        from: 'top',
                        align: 'right'
                      }
                    });

                  },
                  _ => {
                    //    this.alertService.error(error);
                    // console.log("sendCreateEmail KO");
                    // console.log(error);
                    // this.loading = false;
                  });
            }

            Swal.fire(
              'Modifi&eacute; !',
              'L&#039;utilisateur a &eacute;t&eacute; modifi&eacute;',
              'success'
            )
              .then(
                this.router.navigate(['user/create'])
              );
            // f.submitted = false
            // this.reloadUsers();

          } else {
            this.loading = false;
            // this.wrongCred = true;
            // this.router.navigate(["login"]);
            Swal.fire(
              'Erreur ! - ' + data['reason'],
              'L&#039;utilisateur n&#039;a pu &ecirc;tre modifi&eacute;',
              'error'
            );

          }
        },
        _ => {
          // this.alertService.error(error);
          // console.log(error);
          this.loading = false;
        });

  }

  generatePassword() {
    // length: number, useUpper: boolean, useNumbers: boolean, userSymbols: boolean
    this.userUpd.password = this.userService.generatePassword(8 + 2, true, true, false);
  }


  reloadProjects1() {
    this.loading = true;
    this.http.get('hr/point/hrPointageProjectsListsGet?userId=' + this.id).subscribe(
      data => {
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrPointagesProjectsAll = data['projects'].sort((a, b) => a.Project.localeCompare(b.Project, 'fr', {'sensitivity': 'base'}));
        this.hrPointagesProjects1 = this.hrPointagesProjectsAll.filter((project) => project.parentId == null).filter((project) => project.Type == 'P' || project.Type == 'A');
        this.hrPointagesProjects2 = this.hrPointagesProjectsAll.filter((project) => project.parentId != null).filter((project) => project.Type == 'P' || project.Type == 'A');
        this.hrPointagesProjects1 = this.merge(this.hrPointagesProjects1, this.hrPointagesProjects2, 'id', 'parentId');
        // Projet et archivé seulement

        this.loading = false;
      });
  }

  merge(arr1, arr2, prop1, prop2) {
    arr1.forEach(e => e['SubProject'] = arr2.filter(el => el[prop2] == e[prop1]).map(s => s.Project));
    return arr1;
  }

  reloadProjects2() {
    this.http.get('hr/point/hrPointageProjectsListsGetAllForGest').subscribe(
      data => {
        // console.log('AccueilComponent-Backend-reloadPointagesProjectsAll returned body was: ' + JSON.stringify(data));
        if (data['error']) {

          // this.model.error = data['error']['message'];
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrPointagesProjectsAll = data['projects'];
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  getTeams() {
    this.loadingTeams = true;

    this.http.get('hr/team/hrTeamsOfUser?userId=' + this.id).subscribe(
      data => {
        // console.log('UpdateComponent-Backend-hrTeamsOfUser returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
          // console.log('ERREUR ERREUR ERREUR ERREUR ERREUR');
        }
        this.AllTeams = data['teamsofuser'];
        // console.log(this.AllTeams);
        this.loadingTeams = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }
}
