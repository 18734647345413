import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appQuarter]',
    providers: [{provide: NG_VALIDATORS, useExisting: QuarterValidatorDirective, multi: true}]
})
export class QuarterValidatorDirective implements Validator {
    // @Input('appForbiddenName') forbiddenName: string;

    // validate(control: AbstractControl): {[key: string]: any} | null {
    validate(control: AbstractControl) {
        // return this.forbiddenName ? forbiddenNameValidator(new RegExp(this.forbiddenName, 'i'))(control)
        //                          : null;
        // return control.value;
        // console.log("QuarterValidatorDirective");
        return null;
        // return { 'custom': true };
    }
}
