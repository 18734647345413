import {formatDate, registerLocaleData} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import Swal from '../../assets/js/plugins/sweetalert2';
import {environment} from '../../environments/environment';
import {User} from '../_models';
import {AuthenticationService, HrService, UserService} from '../_services';
import {MediaService} from '../_services/media.service';
import {DatesHelper, UiHelper} from '../_helpers';

registerLocaleData(localeFr, 'fr');

declare var $: any;

@Component({
  selector: 'app-accueil',
  templateUrl: './pointeuse.component.html',
  styleUrls: ['./pointeuse.component.css']
})
export class PointeuseComponent implements OnInit, AfterViewInit, OnDestroy {

  public currentUser: User;
  public userId: any;

  public loadingPointages = true;
  public loadingCompteurs = true;
  public loadingWeek = true;
  public loadingProjects = true;

  public hrPointages: any;
  public hrCompteurs: any;
  public hrCompteursDay: any;
  public hrCompteursDayHours = 0;
  public hrPointageRunning = 0;
  public hrPointDate: any;
  public hrCompteursDayOfWeek: any;
  public hrCompteursOfDay: any;
  public hrCompteursMonth: any;
  public weeksValidated: any;
  public curDate: Date;
  public todayDate: Date;
  public interval: any;
  public dcUpdate: any;
  public dcUpdateEl: any;

  public projectList: any;
  public modalProjectSelected: any;
  public modalSubProjectSelected: any;
  public roundHours = true;
  public allowDirectPointage = false;
  private hiddenProjectSelected: any;
  private hiddenSubProjectSelected: any;

  public pointageCreateHour: string;

  get projectSelected() {
    return this.hiddenProjectSelected;
  }

  set projectSelected(val) {
    this.modalProjectSelected = val;
    this.hiddenProjectSelected = val;
  }

  get subProjectSelected() {
    return this.hiddenSubProjectSelected;
  }

  set subProjectSelected(val) {
    this.modalSubProjectSelected = val;
    this.hiddenSubProjectSelected = val;
    setTimeout(function () {
      $('.subProjectSelector').selectpicker('refresh');
    }, 0);
  }

  projectLoading = true;
  subProjectLoading = true;
  loading = true;

  @ViewChildren('projectSelector') projectSelector: any;
  @ViewChildren('subProjectSelector') subProjectSelector: any;
  @ViewChildren('modalProjectSelector') modalProjectSelector: any;
  @ViewChildren('modalSubProjectSelector') modalSubProjectSelector: any;

  modalLoading = false;
  dcCreate: any;
  pointageCreate: any;
  dcCreateEl: any;

  dtPickJ: any;

  cfgHideSourceForUsers: any;
  cfgHidePointeuse: any;
  cfgDefaultPoint: any;
  cfgValidationSemaine: any;
  cfgSimplePointeuse: any;

  currentWeekOfYear: number;
  dateRangeOfWeek: any;
  dateRangeOfWeekFrom: Date;
  dateRangeOfWeekTo: Date;
  commHeight: number;

  simple = false;
  public pointeuseFirst = false;

  isDesktop = true;
  FacturationMode: any;
  FacturationModeList: any;
  private mediaService = new MediaService('(min-width: 768px)');

  public expand_week_activity = true;
  public expand_compteur = true;
  public expand_day_activity = true;
  public expand_pointage = true;
  public expand_pointeuse = true;
  public expand_calendar = true;
  public expand_quickaccess = true;

  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService,
    private hrService: HrService,
    private datesHelper: DatesHelper,
    public ui: UiHelper,
    public userService: UserService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    userService.getEntreprise(this.currentUser.entreprise_id).subscribe(ent => this.allowDirectPointage = ent.Configs.some(c => c.ConfigKey == 'User' && c.ConfigSubKey == 'AddPointage' && c.ConfigValue == '1'));
    this.userId = authenticationService.decodedUserId;
    authenticationService.reloadEntrepriseFromLocalStorage();
    this.curDate = new Date();
    this.todayDate = new Date();
  }

  ngOnInit() {
    this.mediaService.match$.subscribe(value => this.isDesktop = value);
    this.authenticationService.getLastVersion()
      .subscribe(
        data => {
          if (environment.currentVersion != data.version) {
            location.reload();
          }
          this.loading = false;
        },
        _ => {
        });
    const HideSourceForUsersConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'HideSourceForUsers')[0];
    if (HideSourceForUsersConfig != undefined) {
      this.cfgHideSourceForUsers = HideSourceForUsersConfig.ConfigValue;
    }

    const ValidationSemaineConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'ValidationSemaine')[0];
    if (ValidationSemaineConfig != undefined) {
      this.cfgValidationSemaine = ValidationSemaineConfig.ConfigValue;
    } else {
      this.cfgValidationSemaine = 1;
    } // Par défaut : validation de la semaine possible

    const HidePointeuseConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'HidePointeuse')[0];
    if (HidePointeuseConfig != undefined) {
      this.cfgHidePointeuse = HidePointeuseConfig.ConfigValue;
    }

    const DefaultPointConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'DefaultPoint')[0];
    if (DefaultPointConfig != undefined) {
      this.cfgDefaultPoint = DefaultPointConfig.ConfigValue;
    } else {
      this.cfgDefaultPoint = 'W';
    } // Par défaut : pas de validation

    const SimplePointeuseConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'SimplePointeuse')[0];
    if (SimplePointeuseConfig != undefined) {
      this.cfgSimplePointeuse = SimplePointeuseConfig.ConfigValue;
    } else {
      this.cfgSimplePointeuse = 0;
    } // Par défaut : pointeuse complete avec navigation dans les jours + recaps

    this.loadCookies();
    this.hrPointDate = formatDate(this.curDate, 'dd.MM.yyyy', 'fr'); // "29.04.2019";
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  ngAfterViewInit() {
    this.reloadPointagesProjectsAll();

    this.dcCreate = {
      'dcComm': '',
      'dcDuree': 0
    };
    this.dcUpdate = {
      'dcComm': '',
      'dcDuree': 0
    };
    this.refreshHighlightDays(this.curDate);

    this.realoadDC();

    this.interval = setTimeout(() => this.reloadAll(), 60 * 1000);


    this.dtPickJ = $('.datetimepicker').datetimepicker({
      locale: 'fr-ch',
      format: 'L',
      showTodayButton: false,
      maxDate: $.now(),
      inline: true,
      calendarWeeks: true,
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-calendar',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
    this.dtPickJ.on('dp.change', (e) => {
      this.onPointDate(e.date);
    });
    this.dtPickJ.on('dp.update', (e) => {
      this.onPointDatepickernav(e);
    });

    this.dateChanged();

    this.projectSelector.changes.subscribe(_ => {
      setTimeout(function () {
        $('.projectSelector').selectpicker('refresh');
      }, 0);
    });
    this.subProjectSelector.changes.subscribe(_ => {
      setTimeout(function () {
        $('.subProjectSelector').selectpicker('refresh');
      }, 0);
    });

    $('.selectpicker').selectpicker();

    $('[data-toggle="tooltip"]').tooltip();
  }

  /*
                        $$\                           $$\  $$$$$$\  $$\ $$\
                        $$ |                          $$ |$$  __$$\ $$ |$$ |
     $$$$$$\   $$$$$$\  $$ | $$$$$$\   $$$$$$\   $$$$$$$ |$$ /  $$ |$$ |$$ |
    $$  __$$\ $$  __$$\ $$ |$$  __$$\  \____$$\ $$  __$$ |$$$$$$$$ |$$ |$$ |
    $$ |  \__|$$$$$$$$ |$$ |$$ /  $$ | $$$$$$$ |$$ /  $$ |$$  __$$ |$$ |$$ |
    $$ |      $$   ____|$$ |$$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |
    $$ |      \$$$$$$$\ $$ |\$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$ |  $$ |$$ |$$ |
    \__|       \_______|\__| \______/  \_______| \_______|\__|  \__|\__|\__| */


  reloadAll() {
    this.loadingPointages = true;
    this.loadingCompteurs = true;
    this.reloadPointages();
    this.reloadCompteurs();
    this.reloadPointagesIsRunning();
    this.interval = setTimeout(() => this.reloadAll(), 60 * 1000);
  }

  reloadPointages() {
    let curDatemysql;
    let parameters;
    if (this.curDate) {
      curDatemysql = formatDate(this.curDate, 'yyyy-MM-dd', 'fr');
      parameters = '&dateFrom=' + curDatemysql + '&dateTo=' + curDatemysql;
    } else {
      parameters = '&period=d';
    }
    this.http.get('hr/point/hrPointageUserGet?userId=' + this.userId + parameters).subscribe(
      data => {
        if (data['error']) {
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrPointages = data['pointages'];
        this.hrCompteursDay = data['compteursDay'];

        this.hrCompteursDayHours = 0;
        for (let i = 0; i < this.hrCompteursDay.length; i++) {
          this.hrCompteursDayHours += this.hrCompteursDay[i].Hours;
        }

        this.loadingPointages = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  reloadCompteurs() {
    this.http.get('hr/point/hrPointageCompteursUserGet?userId=' + this.userId).subscribe(
      data => {
        if (data['error']) {
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrCompteurs = data['compteurs'];

        this.loadingCompteurs = false;

        setTimeout(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });

      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  reloadPointagesIsRunning() {
    this.http.get('hr/point/hrPointageUserIsRunning?userId=' + this.userId).subscribe(
      data => {
        if (data['error']) {
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.hrPointageRunning = data['running'];
        /*this.FacturationMode = this.FacturationModeList.filter(f => f.ConfigValue == data['projet'][0]['FacturationMode'])[0];*/
        if (this.hrPointageRunning > 0) {
          if (data['projet'][0] != undefined) {
            this.projectSelected = this.projectList.filter(p => p.id == data['projet'][0]['Project1Id'])[0];

            try {
              this.subProjectSelected = this.projectSelected.SubProjectList.filter(p => p.id == data['projet'][0]['Project2Id'])[0];
            } catch (e) {
              console.error(e);
            }
          }
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  /*
    $$$$$$$\                                     $$\
    $$  __$$\                                    $$ |
    $$ |  $$ | $$$$$$\   $$$$$$\  $$\  $$$$$$\ $$$$$$\    $$$$$$$\
    $$$$$$$  |$$  __$$\ $$  __$$\ \__|$$  __$$\\_$$  _|  $$  _____|
    $$  ____/ $$ |  \__|$$ /  $$ |$$\ $$$$$$$$ | $$ |    \$$$$$$\
    $$ |      $$ |      $$ |  $$ |$$ |$$   ____| $$ |$$\  \____$$\
    $$ |      $$ |      \$$$$$$  |$$ |\$$$$$$$\  \$$$$  |$$$$$$$  |
    \__|      \__|       \______/ $$ | \_______|  \____/ \_______/
                            $$\   $$ |
                            \$$$$$$  |
                             \______/
  */

  // Pour affichage des noms de projets
  reloadPointagesProjectsAll() {
    this.http.get('hr/project/hrProjectsParentForGuest').subscribe(
      data => {
        if (data['error']) {
          location.reload(); // recharge la page car surement pb de login -> login
        }
        this.projectList = data['project'];
        this.loadChildren();
      },
      _ => {
        location.reload(); // recharge la page car surement pb de login -> login
      });
  }

  async loadChildren() {
    this.http.get('hr/project/hrProjectsChildrensForGuest').subscribe(
      data => {
        if (data['errorMessage']) {
          console.error(data);
        } else {
          try {
            const subProject = data['project'];
            this.projectList.forEach(p => p.SubProjectList = subProject.filter(pp => pp.parentId == p.id));
            this.reloadPointagesIsRunning();
            this.subProjectLoading = false;
          } catch (e) {
            console.error(e);
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      });
  }

  /*$$$$$$$\            $$\            $$\
    $$  __$$\           \__|           $$ |
    $$ |  $$ | $$$$$$\  $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\   $$$$$$\
    $$$$$$$  |$$  __$$\ $$ |$$  __$$\\_$$  _|   \____$$\ $$  __$$\ $$  __$$\
    $$  ____/ $$ /  $$ |$$ |$$ |  $$ | $$ |     $$$$$$$ |$$ /  $$ |$$$$$$$$ |
    $$ |      $$ |  $$ |$$ |$$ |  $$ | $$ |$$\ $$  __$$ |$$ |  $$ |$$   ____|
    $$ |      \$$$$$$  |$$ |$$ |  $$ | \$$$$  |\$$$$$$$ |\$$$$$$$ |\$$$$$$$\
    \__|       \______/ \__|\__|  \__|  \____/  \_______| \____$$ | \_______|
                                                         $$\   $$ |
                                                         \$$$$$$  |
                                                          \______/*/

  onPointage() {
    if (this.projectSelected == null || this.subProjectSelected == null) {
      return;
    }
    this.hrPointageRunning = 2;
    const body = {
      'userId': this.userId,
      'source': this.cfgDefaultPoint,  // W : Direct, V passe par validation
      'project1': this.projectSelected.id,
      'project2': this.subProjectSelected.id,
      // 'facturationMode': this.FacturationMode.ConfigValue
    };
    this.http.post('hr/point/hrPointage', body).subscribe(
      _ => {
        // this.reloadFacturation();
        this.reloadPointages();
        this.reloadPointagesIsRunning();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*$$$$$$$\             $$\            $$$$$$$$\ $$\                         $$$$$$$\  $$\           $$\
    $$  __$$\            $$ |           \__$$  __|\__|                        $$  __$$\ \__|          $$ |
    $$ |  $$ | $$$$$$\ $$$$$$\    $$$$$$\  $$ |   $$\ $$$$$$\$$$$\   $$$$$$\  $$ |  $$ |$$\  $$$$$$$\ $$ |  $$\  $$$$$$\   $$$$$$\
    $$ |  $$ | \____$$\\_$$  _|  $$  __$$\ $$ |   $$ |$$  _$$  _$$\ $$  __$$\ $$$$$$$  |$$ |$$  _____|$$ | $$  |$$  __$$\ $$  __$$\
    $$ |  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$ |   $$ |$$ / $$ / $$ |$$$$$$$$ |$$  ____/ $$ |$$ /      $$$$$$  / $$$$$$$$ |$$ |  \__|
    $$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |   $$ |$$ | $$ | $$ |$$   ____|$$ |      $$ |$$ |      $$  _$$<  $$   ____|$$ |
    $$$$$$$  |\$$$$$$$ | \$$$$  |\$$$$$$$\ $$ |   $$ |$$ | $$ | $$ |\$$$$$$$\ $$ |      $$ |\$$$$$$$\ $$ | \$$\ \$$$$$$$\ $$ |
    \_______/  \_______|  \____/  \_______|\__|   \__|\__| \__| \__| \_______|\__|      \__| \_______|\__|  \__| \_______|\__|*/

  onClickDtPrev() {
    this.curDate.setDate(this.curDate.getDate() - 1);
    this.hrPointDate = formatDate(this.curDate, 'dd.MM.yyyy', 'fr'); // "29.04.2019";
    this.dateChanged();
  }

  onClickDtNext() {
    const yester = new Date(); // = new Date();
    yester.setDate(new Date().getDate() - 1);
    if (this.curDate < yester) {
      this.curDate.setDate(this.curDate.getDate() + 1);
      this.hrPointDate = formatDate(this.curDate, 'dd.MM.yyyy', 'fr'); // "29.04.2019";
      this.dateChanged();
    }
  }

  onPointDate(newDate) {
    this.curDate = newDate._d;
    this.dateChanged();
  }

  onPointDatepickernav(e) {
    this.refreshHighlightDays(e.viewDate);

    // TODO : curDate OK, mais pas affiché
    this.curDate = new Date(e.viewDate);
    this.dateChanged();

    setTimeout(function () {
      $('.datetimepicker').data('DateTimePicker').date(new Date(this.curDate));
    }, 0);
  }

  /*
                         $$$$$$\                               $$\       $$\   $$\ $$\           $$\       $$\ $$\           $$\        $$\     $$$$$$$\
                        $$  __$$\                              $$ |      $$ |  $$ |\__|          $$ |      $$ |\__|          $$ |       $$ |    $$  __$$\
     $$$$$$\   $$$$$$\  $$ /  \__|$$$$$$\   $$$$$$\   $$$$$$$\ $$$$$$$\  $$ |  $$ |$$\  $$$$$$\  $$$$$$$\  $$ |$$\  $$$$$$\  $$$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$\  $$\   $$\  $$$$$$$\
    $$  __$$\ $$  __$$\ $$$$\    $$  __$$\ $$  __$$\ $$  _____|$$  __$$\ $$$$$$$$ |$$ |$$  __$$\ $$  __$$\ $$ |$$ |$$  __$$\ $$  __$$\\_$$  _|  $$ |  $$ | \____$$\ $$ |  $$ |$$  _____|
    $$ |  \__|$$$$$$$$ |$$  _|   $$ |  \__|$$$$$$$$ |\$$$$$$\  $$ |  $$ |$$  __$$ |$$ |$$ /  $$ |$$ |  $$ |$$ |$$ |$$ /  $$ |$$ |  $$ | $$ |    $$ |  $$ | $$$$$$$ |$$ |  $$ |\$$$$$$\
    $$ |      $$   ____|$$ |     $$ |      $$   ____| \____$$\ $$ |  $$ |$$ |  $$ |$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$ |  $$ |$$  __$$ |$$ |  $$ | \____$$\
    $$ |      \$$$$$$$\ $$ |     $$ |      \$$$$$$$\ $$$$$$$  |$$ |  $$ |$$ |  $$ |$$ |\$$$$$$$ |$$ |  $$ |$$ |$$ |\$$$$$$$ |$$ |  $$ | \$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$$$$$$  |
    \__|       \_______|\__|     \__|       \_______|\_______/ \__|  \__|\__|  \__|\__| \____$$ |\__|  \__|\__|\__| \____$$ |\__|  \__|  \____/ \_______/  \_______| \____$$ |\_______/
                                                                                       $$\   $$ |                  $$\   $$ |                                       $$\   $$ |
                                                                                       \$$$$$$  |                  \$$$$$$  |                                       \$$$$$$  |
                                                                                        \______/                    \______/                                         \______/
  */

  refreshHighlightDays(viewDate) {
    // let vie
    const url = 'hr/point/hrGetCounterDayExistingDates?userId=' + this.userId + '&month=' + formatDate(viewDate, 'MM', 'fr')
      + '&year=' + formatDate(viewDate, 'yyyy', 'fr');
    this.http.get(url)
      .subscribe(
        data => {
          if (data['error']) {
            location.reload(); // recharge la page car surement pb de login -> login
          }

          let dte, classDte, classDte2;
          for (const entry of data['CounterDayExistingDates']) {
            dte = entry['CounterDayExistingDate'];

            classDte = dte.substring(8, 10) + '.' + dte.substring(5, 7) + '.' + dte.substring(0, 4);
            classDte2 = dte.substring(8, 10) + '/' + dte.substring(5, 7) + '/' + dte.substring(0, 4);

            const element = $('[data-day="' + classDte + '"]');
            element.addClass('dayHighlight');

            if (entry['Calc'] == 8) {
              element.addClass('dayAlert');
            }
            if (entry['Calc'] == 9) {
              element.addClass('dayAlert');
            }
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            location.reload(); // recharge la page car surement pb de login -> login
          } else {
            location.reload(); // recharge la page car surement pb de login -> login
          }
        });

    this.http.get('hr/counter/hrWeeksValidatedGet?UserId=' + this.userId + '&Year=' + formatDate(viewDate, 'yyyy', 'fr'))
      .subscribe(
        data => {
          if (data['error']) {
            location.reload(); // recharge la page car surement pb de login -> login
          }

          let numW;
          this.weeksValidated = [];
          for (const entry of data['weeksValidated'].filter(w => w.Status === 'V')) {
            numW = entry['Numero'];
            this.weeksValidated.push(numW);

            $('td').filter(function () {
              return $(this).text() == numW;
            }).addClass('validated');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            location.reload(); // recharge la page car surement pb de login -> login
          } else {
            location.reload(); // recharge la page car surement pb de login -> login
          }
        });

  }

  dateChanged() {
    this.loadingPointages = true;
    this.refreshHighlightDays(this.curDate);
    this.reloadPointages();
    this.currentWeekOfYear = this.datesHelper.getWeek(this.curDate);
    this.dateRangeOfWeek = this.datesHelper.getDateRangeOfWeek(this.currentWeekOfYear, this.curDate);
    this.dateRangeOfWeekFrom = this.datesHelper.getDateRangeOfWeekFrom(this.currentWeekOfYear, this.curDate);
    this.dateRangeOfWeekTo = this.datesHelper.getDateRangeOfWeekTo(this.currentWeekOfYear, this.curDate);
    this.reloadCountersDayOfWeek();

    // TODO à faire que si model ouverte
    this.reloadCountersMonth();
  }

  /*
     $$$$$$\              $$\     $$\            $$\   $$\
    $$  __$$\             $$ |    \__|           \__|  $$ |
    $$ /  $$ | $$$$$$$\ $$$$$$\   $$\ $$\    $$\ $$\ $$$$$$\    $$$$$$\   $$$$$$$\
    $$$$$$$$ |$$  _____|\_$$  _|  $$ |\$$\  $$  |$$ |\_$$  _|  $$  __$$\ $$  _____|
    $$  __$$ |$$ /        $$ |    $$ | \$$\$$  / $$ |  $$ |    $$$$$$$$ |\$$$$$$\
    $$ |  $$ |$$ |        $$ |$$\ $$ |  \$$$  /  $$ |  $$ |$$\ $$   ____| \____$$\
    $$ |  $$ |\$$$$$$$\   \$$$$  |$$ |   \$  /   $$ |  \$$$$  |\$$$$$$$\ $$$$$$$  |
    \__|  \__| \_______|   \____/ \__|    \_/    \__|   \____/  \_______|\_______/*/

  dayCountCreate() {
    $('#createDayCountModal').modal('hide');

    const body = {
      'userId': this.userId,
      'date': formatDate(this.curDate, 'yyyy/MM/dd', 'fr'),
      'hours': this.dcCreate.dcDuree,
      'source': 'L',
      'project1': this.modalProjectSelected.id,
      'project2': this.modalSubProjectSelected.id,
      'comment': this.dcCreate.dcComm
    };
    //  console.info(body);

    this.http.post('hr/counter/hrCounterDayAdd', body).subscribe(
      _ => {
        // Read the result field from the JSON response.
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // this.ec2_desktop_state = data;
        // this.loading = false;
        // if (data['error']) {
        // TODO
        // }
        this.reloadPointages();
        this.reloadCountersDayOfWeek();
        this.reloadCountersMonth();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  reloadCountersDayOfWeek() {
    this.hrService.hrCounterDayUserGet(this.userId, this.dateRangeOfWeekFrom, this.dateRangeOfWeekTo)
      .subscribe(
        data => {
          this.hrCompteursDayOfWeek = data['counterday'];
          const tempData = {};
          data['counterday'].forEach(function (a) {
            const dateGroup = formatDate(Date.parse(a.Date), 'yyyy.MM.dd', 'fr-ch');
            tempData [dateGroup] = tempData [dateGroup] || [];
            tempData [dateGroup].push(a);
          });
          this.hrCompteursOfDay = tempData;
          this.loadingWeek = false;
        },
        _ => {
          location.reload(); // recharge la page car surement pb de login -> login
        });
  }

  reloadCountersMonth() {
    this.hrService.hrCounterDayUserGetGroupBy(
      this.userId,
      this.datesHelper.getFirstDayOfMonth(this.curDate),
      this.datesHelper.getLastDayOfMonth(this.curDate),
      false)
      .subscribe(
        data => {
          this.hrCompteursMonth = data['counterdaygroup'];
        },
        _ => {
          location.reload(); // recharge la page car surement pb de login -> login
        });
  }

  /*
                         $$$$$$\                                 $$$$$$\                                 $$\
                        $$  __$$\                               $$  __$$\                                $$ |
     $$$$$$\   $$$$$$\  $$ /  \__|$$\   $$\  $$$$$$$\  $$$$$$\  $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\
    $$  __$$\ $$  __$$\ $$$$\     $$ |  $$ |$$  _____|$$  __$$\ $$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\
    $$ |  \__|$$$$$$$$ |$$  _|    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|
    $$ |      $$   ____|$$ |      $$ |  $$ | \____$$\ $$   ____|$$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |
    $$ |      \$$$$$$$\ $$ |      \$$$$$$  |$$$$$$$  |\$$$$$$$\ \$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |
    \__|       \_______|\__|       \______/ \_______/  \_______| \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|*/


  refuseCounter(cdId) {
    this.loadingPointages = true;
    this.loadingWeek = true;

    const body = {
      'cdId': cdId
    };

    this.http.post('hr/counter/hrCounterDayRefuse', body).subscribe(
      _ => {
        this.reloadPointages();
        this.reloadCountersDayOfWeek();
        this.reloadCountersMonth();
        this.loadingPointages = false;
        this.loadingWeek = false;

      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*
                                            $$\   $$\                 $$\            $$\                $$$$$$\                                 $$\                         $$\      $$\                 $$\           $$\
                                            $$ |  $$ |                $$ |           $$ |              $$  __$$\                                $$ |                        $$$\    $$$ |                $$ |          $$ |
     $$$$$$\   $$$$$$\   $$$$$$\  $$$$$$$\  $$ |  $$ | $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\  $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\  $$$$\  $$$$ | $$$$$$\   $$$$$$$ | $$$$$$\  $$ |
    $$  __$$\ $$  __$$\ $$  __$$\ $$  __$$\ $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\ $$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\ $$\$$\$$ $$ |$$  __$$\ $$  __$$ | \____$$\ $$ |
    $$ /  $$ |$$ /  $$ |$$$$$$$$ |$$ |  $$ |$$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|$$ \$$$  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ |$$ |
    $$ |  $$ |$$ |  $$ |$$   ____|$$ |  $$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |      $$ |\$  /$$ |$$ |  $$ |$$ |  $$ |$$  __$$ |$$ |
    \$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |  $$ |\$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\ \$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |      $$ | \_/ $$ |\$$$$$$  |\$$$$$$$ |\$$$$$$$ |$$ |
     \______/ $$  ____/  \_______|\__|  \__| \______/ $$  ____/  \_______| \_______|  \____/  \_______| \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|      \__|     \__| \______/  \_______| \_______|\__|
              $$ |                                    $$ |
              $$ |                                    $$ |
              \__|                                    \__|
  */


  openUpdateCounterModal(cd) {
    this.dcUpdate.duDuree = cd.Hours;
    this.dcUpdate.duComm = cd.Comment;
    this.dcUpdate.id = cd.id;

    this.modalProjectSelected = this.projectList.filter(p => p.id == cd.Project1Id)[0];
    try {
      this.modalSubProjectSelected = this.modalProjectSelected.SubProjectList.filter(p => p.id == cd.Project2Id)[0];
    } catch (e) {
      console.error(e);
    }

    $('#hrPointProjetLvl1ID_DU').selectpicker().val(cd.Project1Id);
    $('#hrPointProjetLvl2ID_DU').selectpicker().val(cd.Project2Id);
    setTimeout(function () {
      $('#hrPointProjetLvl1ID_DU').selectpicker('refresh');
      $('#hrPointProjetLvl2ID_DU').selectpicker('refresh');
    }, 0);
    $('#updateDayCountModal').modal('show');
    this.realoadDC();
  }

  dayCountUpdate() {
    $('#updateDayCountModal').modal('hide');

    this.loadingPointages = true;
    this.loadingWeek = true;

    const body = {
      'userId': this.userId,
      'id': this.dcUpdate.id,
      'hours': this.dcUpdate.duDuree,
      'source': 'L',
      'project1': this.modalProjectSelected.id,
      'project2': this.modalSubProjectSelected.id,
      'comment': this.dcUpdate.duComm
    };
    this.http.post('hr/counter/hrCounterDayUpdate', body).subscribe(
      _ => {
        this.reloadPointages();
        this.reloadCountersDayOfWeek();
        this.reloadCountersMonth();
        this.loadingPointages = false;
        this.loadingWeek = false;
      },
      err => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  /*                                  $$\       $$\    $$\          $$\ $$\       $$\            $$\
                                      $$ |      $$ |   $$ |         $$ |\__|      $$ |           $$ |
    $$\  $$\  $$\  $$$$$$\   $$$$$$\  $$ |  $$\ $$ |   $$ |$$$$$$\  $$ |$$\  $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\
    $$ | $$ | $$ |$$  __$$\ $$  __$$\ $$ | $$  |\$$\  $$  |\____$$\ $$ |$$ |$$  __$$ | \____$$\\_$$  _|  $$  __$$\
    $$ | $$ | $$ |$$$$$$$$ |$$$$$$$$ |$$$$$$  /  \$$\$$  / $$$$$$$ |$$ |$$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |
    $$ | $$ | $$ |$$   ____|$$   ____|$$  _$$<    \$$$  / $$  __$$ |$$ |$$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|
    \$$$$$\$$$$  |\$$$$$$$\ \$$$$$$$\ $$ | \$$\    \$  /  \$$$$$$$ |$$ |$$ |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\
     \_____\____/  \_______| \_______|\__|  \__|    \_/    \_______|\__|\__| \_______| \_______|  \____/  \_______|*/


  autoResize() {
    this.commHeight = $('#dcComm')[0].scrollHeight;
  }

  weekValidate() {
    const body = {
      'UserId': this.userId,
      'Year': formatDate(this.curDate, 'yyyy', 'fr'),
      'Week': this.currentWeekOfYear
    };
    Swal.fire({
      title: 'Valider la semaine ?',
      text: 'Voulez-vous valider cette semaine ? Vous ne pourrez ensuite plus saisir ni modifier d\'Activités sur cette semaine.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Valider !',
      cancelButtonText: 'Non, Annuler',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.http.post('hr/counter/hrWeeksValidate', body).subscribe(
          _ => {
            this.refreshHighlightDays(this.curDate);
          },
          _ => {
            location.reload(); // recharge la page car surement pb de login -> login
          }
        );
      }
    });
  }

  simplifier() {
    this.simple = !this.simple;
    localStorage.setItem('simple', this.simple ? '1' : '0');
  }

  saveCookies() {
    localStorage.setItem('expand_calendar', this.expand_calendar ? '1' : '0');
    localStorage.setItem('expand_compteur', this.expand_compteur ? '1' : '0');
    localStorage.setItem('expand_pointage', this.expand_pointage ? '1' : '0');
    localStorage.setItem('expand_day_activity', this.expand_day_activity ? '1' : '0');
    localStorage.setItem('expand_pointeuse', this.expand_pointeuse ? '1' : '0');
    localStorage.setItem('expand_quickaccess', this.expand_quickaccess ? '1' : '0');
    localStorage.setItem('expand_week_activity', this.expand_week_activity ? '1' : '0');
  }

  loadCookies() {
    this.expand_calendar = localStorage.getItem('expand_calendar') != '0';
    this.expand_compteur = localStorage.getItem('expand_compteur') != '0';
    this.expand_pointage = localStorage.getItem('expand_pointage') != '0';
    this.expand_day_activity = localStorage.getItem('expand_day_activity') != '0';
    this.expand_pointeuse = localStorage.getItem('expand_pointeuse') != '0';
    this.expand_quickaccess = localStorage.getItem('expand_quickaccess') != '0';
    this.expand_week_activity = localStorage.getItem('expand_week_activity') != '0';
    this.pointeuseFirst = !(localStorage.getItem('pointeuseFirst') == '0');
  }

  changepointeuseFirst() {
    localStorage.setItem('pointeuseFirst', !this.pointeuseFirst ? '1' : '0');
    this.loadCookies();
  }

  public realoadDC() {
    this.dcUpdateEl = $('#duDuree')[0];
    this.dcCreateEl = $('#dcDuree')[0];
  }

  public setDate(date: Date) {
    this.curDate = new Date(date);
    this.todayDate = new Date(date);
    this.dateChanged();
    setTimeout(function () {
      $('.datetimepicker').data('DateTimePicker').date(new Date(date));
    }, 0);
  }

  getTimeProject(projectId: number, callback) {
    this.http.get('hr/project/' + projectId + '/detail')
      .subscribe(
        data => {
          if (data['error']) {
            location.reload(); // recharge la page car surement pb de login -> login
          }
          callback(data);
        },
        err => {
          console.error(err);
        });
  }

  getTimeSubProject(projectId: number, callback) {
    this.http.get('hr/project/sub/' + projectId + '/detail')
      .subscribe(
        data => {
          if (data['error']) {
            location.reload(); // recharge la page car surement pb de login -> login
          }
          callback(data);
        },
        err => {
          console.error(err);
        });
  }

  SelectedProjectChange(loadTime = false) {
    try {
      const subProject = this.projectSelected.SubProjectList;
      if (loadTime) {
        this.getTimeProject(this.projectSelected.id, (data) => {
          this.projectSelected.hours = data.projects[0].hours;
          this.projectSelected.SubProjectList = subProject;
          this.projectSelected.SubProjectList.forEach(sp => this.getTimeSubProject(sp.id, (newdata) => sp = newdata.projects[0]));
        });
      }
      this.subProjectSelected = this.projectSelected.SubProjectList[0];
    } catch (e) {
      console.error(e);
    }
  }

  ModalSelectedProjectChange(loadTime = false) {
    try {
      if (loadTime) {
        this.getTimeProject(this.modalProjectSelected.id, (data) => {
          this.modalProjectSelected.Hours = data.projects[0].Hours;
          this.modalProjectSelected.StatAllocated = data.projects[0].StatAllocated;
          for (const subProject of this.modalProjectSelected.SubProjectList) {
            this.getTimeSubProject(subProject.id, (newdata) => {
              subProject.Hours = newdata.projects[0].Hours;
            });
          }
          this.modalSubProjectSelected = this.modalProjectSelected.SubProjectList[0];
        });
      }
      this.modalSubProjectSelected = this.modalProjectSelected.SubProjectList[0];
    } catch (e) {
      console.error(e);
    }
  }

  private reloadFacturation() {
    this.http.get('config/facturation').subscribe(
      data => {
        this.FacturationModeList = data['facturationMode'];
      },
      err => console.error(err)
    );
  }

  PointageCreate() {
    $('#createPointageModal').modal('hide');
    if (this.modalProjectSelected == null || this.modalSubProjectSelected == null) {
      return;
    }
    const body = {
      'userId': this.userId,
      'date': formatDate(this.curDate, 'yyyy/MM/dd', 'fr'),
      'time': this.pointageCreateHour,
      'datetime': formatDate(this.curDate, 'yyyy/MM/dd', 'fr') + ' ' + this.pointageCreateHour,
      'source': this.cfgDefaultPoint,  // W : Direct, V passe par validation
      'project1': this.modalProjectSelected.id,
      'project2': this.modalSubProjectSelected.id
      // 'facturationMode': this.FacturationMode.ConfigValue
    };
    this.http.post('hr/point/hrPointage', body).subscribe(
      _ => {
        // this.reloadFacturation();
        this.reloadPointages();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }
}
