export class Config {
  public EntrepriseId: number;
  public ConfigKey = '';
  public ConfigSubKey = '';
  public ConfigValue = '';
  public ConfigKey_old = '';
  public ConfigSubKey_old = '';
  public ConfigValue_old = '';
  public Added: boolean;
  public Deleted = false;
  public Edited = false;

  constructor(obj?) {
    Object.assign(this, obj);
    this.Added = obj == undefined;
    this.ConfigKey_old = this.ConfigKey;
    this.ConfigSubKey_old = this.ConfigSubKey;
    this.ConfigValue_old = this.ConfigValue;
  }
}
