import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchProjects'
})
export class SearchProjectsPipe implements PipeTransform {
    transform(projects: any, term: string): any {
        if (!projects || !term) {
            return projects;
        }
        return projects.filter(project => project.Project.toLowerCase().includes(term.toLowerCase()) || (project.ExternalId === null) ? project.Project.toLowerCase().includes(term.toLowerCase()) : project.ExternalId.toLowerCase().includes(term.toLowerCase()));
    }
}
