import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        let apiURL;
        if (request.url.startsWith('user/') || request.url.startsWith('config/')) {
          apiURL = environment.apiUrlUser;
        } else if (request.url.startsWith('hr/counter/')) {
          apiURL = environment.apiUrlCounter;
        } else if (request.url.startsWith('hr/point/')) {
          apiURL = environment.apiUrlPointage;
        } else if (request.url.startsWith('hr/team/')) {
          apiURL = environment.apiUrlTeam;
        } else if (request.url.startsWith('hr/project/')) {
          apiURL = environment.apiUrlProject;
        }

        if (currentUser && currentUser.token) {
            // console.log("JwtInterceptor-loggedin : "  + currentUser.token);
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                },
                url: apiURL + request.url
            });
        } else {
            // console.log("JwtInterceptor-Not loggedin");
            request = request.clone({
                url: apiURL + request.url
            });
        }

        return next.handle(request);
    }
}
