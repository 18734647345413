import {Component, OnInit} from '@angular/core';
import {Config, Entreprise} from '../_models';
import {AuthenticationService, UserService} from '../_services';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.css']
})
export class EntrepriseComponent implements OnInit {

  public entreprise: Entreprise;
  public configChanged = false;

  constructor(public userService: UserService, public authService: AuthenticationService) {
    userService.getEntreprise(authService.user.entreprise_id).subscribe(entreprise => this.entreprise = entreprise);
  }

  ngOnInit() {
  }

  public update() {
    this.entreprise.Configs = this.entreprise.Configs.filter(conf => conf.Added || conf.Edited || conf.Deleted);
    const result = this.userService.updateEntreprise(this.entreprise);
    result.entrepriseData.then(() => {
      Promise.all(result.confData).then(() => this.userService.getEntreprise(this.authService.user.entreprise_id).subscribe(entreprise => this.entreprise = entreprise));
    });
  }

  public addConfig() {
    this.entreprise.Configs.unshift(new Config());
    this.configChanged = true;
  }

  public saveConfig() {
    this.update();
    this.configChanged = false;
  }
}
