import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    model: any = {};
    loading = false;
    wrongCred = false;
    returnUrl: string;
    entid: number;
    realentid: number;
    entreprise: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient
        // private alertService: AlertService
    ) {
    }

    ngOnInit() {
        // reset login status
        window.setTimeout(() =>
            this.authenticationService.logout()
        );

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        this.route.params.subscribe(params => {
          this.entid = params['entid'];
          if (this.entid != undefined) {
            this.getEntreprise();
          }
        });
    }

    login() {
        this.loading = true;

        if (this.entid != undefined && !this.model.username.includes(this.entreprise.entreprise.FQDN)) {
            this.model.username += '@' + this.entreprise.entreprise.FQDN;
        }
        // console.log("Username=" + this.model.username);

        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    // console.log("login data");
                    // console.log(JSON.stringify(data));
                    if (data != null) {
                        this.authenticationService.getEntreprise().subscribe(
                            _ => {
                                this.router.navigate([this.returnUrl]);
                            }
                        );
                    } else {
                        this.loading = false;
                        this.wrongCred = true;
                      console.info(data);
                        // this.router.navigate(["login"]);
                    }
                },
              error => {
                //  this.alertService.error(error);
                console.info(error);
                this.loading = false;
              });
    }

    getEntreprise() {
        this.http.get('user/entrepriseGet?entrepriseId=' + this.entid).subscribe(
            data => {
                // console.log('LoginComponent-Backend-entrepriseGet returned body was: ' + JSON.stringify(data));
                if (data['error']) {
                    // TODO
                    // this.model.error = data['error']['message'];
                }
                this.entreprise = data;
                this.realentid = this.entreprise.entreprise.id;
                // console.log('Entreprise realentid:' + this.realentid);

                // this.loading = false;
            });
    }
}
