import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchUser'
})
export class SearchUserPipe implements PipeTransform {
    transform(users: any, term: string): any {
        if (!users || !term) {
            return users;
        }
        if (term.includes(('@'))) {
          return users.filter(user => user.username.toLowerCase().includes(term.toLowerCase()) );
        }
        return users.filter(user => (user.prenom + ' ' + user.nom).toLowerCase().includes(term.toLowerCase()) || user.username.toLowerCase().includes(term.toLowerCase()) );
    }
}
