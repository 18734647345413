import {Component, Input, OnInit} from '@angular/core';
import {User} from '../_models';
import {AuthenticationService} from '../_services';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit {

    @Input()
    currentUser: User;
    groups: any;
    statut: string;

    constructor(public authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        // this.groups = this.authenticationService.user.groups.split(",");
        this.groups = JSON.parse(this.authenticationService.user.groups);
        // TODO Config
        this.statut = this.authenticationService.user.ConfigUser1A == 'Emb' ?
            'Embauché'
            : this.statut = this.authenticationService.user.ConfigUser1A == 'Temp' ? 'Temporaire' : 'Non défini';

        // TODO Teams
    }
}
