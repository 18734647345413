import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'hrPointType'})
export class HrPointTypePipe implements PipeTransform {
    transform(type: any): string {

        let typeLib = '?';

        // switch (type) {
        //   case "1":
        //     typeLib = 'Prés.';
        //     break;

        //   default:
        //     typeLib = '? -' + type + '.';
        //     break;
        // }
        if (type == '1') {
            typeLib = 'Prés.';
        }
        return typeLib;
    }
}
