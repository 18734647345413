import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

    cfgMgrLogToMgr: any;

    constructor(
        public authenticationService: AuthenticationService,
        private router: Router
    ) {
    }

    ngOnInit() {
        const MgrLogToMgrConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'MgrLogToMgr')[0];
        if (MgrLogToMgrConfig != undefined) {
            this.cfgMgrLogToMgr = MgrLogToMgrConfig.ConfigValue;
        } else {
            this.cfgMgrLogToMgr = 0;
        } // Par défaut : Les Manager se connecte vers la Pointeuse


        // MgrLogToMgr
        if (this.authenticationService.user.groups != null && this.authenticationService.user.groups.indexOf('"hr:manager"') > 0 && this.cfgMgrLogToMgr == 1) {
            this.router.navigate(['/hrmanager']);
        } else {
            this.router.navigate(['/pointeuse']);
        }
    }
}
