import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {AppPasswordDirective} from './_directives/app-password.directive';
import {QuarterValidatorDirective} from './_directives/validator-quarter.directive';
import {AuthGuard, RoleGuard} from './_guards';
import {DatesHelper, JwtInterceptor, UiHelper} from './_helpers';
import {AuthenticationService, HrService, UserService} from './_services';
import {AccountComponent} from './account/account.component';
import {AccueilComponent} from './accueil/accueil.component';
import {AppComponent} from './app.component';
import {HrPointSourcePipe} from './hr-point-source.pipe';
import {HrmanagerComponent} from './hrmanager/hrmanager.component'; // NON UTILISE
import {LoginComponent} from './login/login.component';
import {NoauthComponent} from './noauth/noauth.component';
import {HrPointTypePipe} from './pointeuse/hr-point-type.pipe';
import {PointeuseComponent} from './pointeuse/pointeuse.component';
import {ProjectsPipe} from './pointeuse/projects.pipe';
import {ProjectEditComponent} from './project-edit/project-edit.component';
import {ProjectsComponent} from './projects/projects.component';
import {SearchProjectsPipe} from './projects/searchProjects.pipe';
import {ReportsComponent} from './reports/reports.component';
import {SupportComponent} from './support/support.component';
import {TeamsEditComponent} from './teams/teams-edit/teams-edit.component';
import {TeamsListComponent} from './teams/teams-list/teams-list.component';
import {UpdateComponent} from './user/update/update.component';
import {UserCreateComponent} from './user/user-create/user-create.component';
import {WeekOfYearPipe} from './weekOfYear.pipe';
import {SearchUserPipe} from './user/user-create/searchUser.pipe';
import {MediaService} from './_services/media.service';
import {PointageListComponent} from './pointage-list/pointage-list.component';
import {UserPointageReportComponent} from './pointage-list/user-pointage-report/user-pointage-report.component';
import {EntrepriseComponent} from './entreprise/entreprise.component';

const appRoutes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'login/:entid', component: LoginComponent},
  {path: 'noauth', component: NoauthComponent},
  {path: 'accueil', component: AccueilComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: AccueilComponent, canActivate: [AuthGuard]},
  {path: 'pointeuse', component: PointeuseComponent, canActivate: [AuthGuard]},
  {path: 'hrmanager', component: HrmanagerComponent, canActivate: [AuthGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  {path: 'reportsPoint', component: PointageListComponent, canActivate: [AuthGuard]},
  {path: 'reportsUserPoint', component: UserPointageReportComponent, canActivate: [AuthGuard]},
  {path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard]},
  {path: 'projects/edit/:id', component: ProjectEditComponent, canActivate: [AuthGuard]},
  {path: 'teams/list', component: TeamsListComponent, canActivate: [AuthGuard]},
  {path: 'teams/edit/:id', component: TeamsEditComponent, canActivate: [AuthGuard]},
  {path: 'user/create', component: UserCreateComponent, canActivate: [AuthGuard]},
  {path: 'user/update/:id', component: UpdateComponent, canActivate: [AuthGuard]},
  {path: 'entreprise', component: EntrepriseComponent, canActivate: [AuthGuard]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
  {path: 'support', component: SupportComponent},
  {path: '', redirectTo: '/accueil', pathMatch: 'full', canActivate: [AuthGuard]},
  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];


@NgModule({
    declarations: [
        AppComponent,
        PointeuseComponent,
        LoginComponent,
        NoauthComponent,
        AccountComponent,
        ProjectsPipe,
        HrPointTypePipe,
        HrPointSourcePipe,
        WeekOfYearPipe,
        SearchProjectsPipe,
        SearchUserPipe,
        ProjectsComponent,
      ProjectEditComponent,
      HrmanagerComponent,
      UserCreateComponent,
      SupportComponent,
      AppPasswordDirective,
      QuarterValidatorDirective,
      ReportsComponent,
      AccueilComponent,
      UpdateComponent,
      TeamsListComponent,
      TeamsEditComponent,
      PointageListComponent,
      UserPointageReportComponent,
      EntrepriseComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(
          appRoutes,
          {enableTracing: false} // <-- debugging purposes only
        )
    ],
    providers: [
      AuthGuard,
      RoleGuard,
      AuthenticationService,
      UiHelper,
      DatesHelper,
      SearchProjectsPipe,
      SearchUserPipe,
      UserService,
      MediaService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
      },
        HrService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
