import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthenticationService, HrService} from '../../_services';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';
import DataTable from '../../../assets/js/plugins/datatables.min';

declare var $: any;

@Component({
  selector: 'app-user-pointage-report',
  templateUrl: './user-pointage-report.component.html',
  styleUrls: ['./user-pointage-report.component.css']
})
export class UserPointageReportComponent implements OnInit, AfterViewInit {
  loading = true;
  data: any;
  table: DataTable;
  filteredTable: any;
  dtData: any;
  currentUser: any;
  showDetailed = true;

  perStart: string;
  perStop: string;
  perStartDate: Date;
  perStopDate: Date;
  filterOnlyCounted = false;
  filterOn = false;
  deltaTimePreset = [];
  deltaTimePresetSelected: any;


  constructor(private hrService: HrService,
              private http: HttpClient,
              public authenticationService: AuthenticationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser['id'] = authenticationService.decodedUserId;
    this.perStopDate = new Date();
    this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
    this.perStartDate = new Date(formatDate(this.perStopDate, 'yyyy-MM-01', 'fr'));
    this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');

    $('#report thead tr').clone(true).appendTo('#report thead');

    [
      {title: 'Aujourd\'hui', start: new Date(), end: new Date()},
      {title: 'Hier', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: new Date(new Date().setDate(new Date().getDate() - 1))},
      {title: 'Ce mois', start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date()},
      {title: 'Le mois dernier', start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: '6 derniers mois', start: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: '12 derniers mois', start: new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0)},
      {title: 'Cette année', start: new Date(new Date().getFullYear(), 0, 1), end: new Date()},
      {title: 'L\'année précédente', start: new Date(new Date().getFullYear() - 1, 0, 1), end: new Date(new Date().getFullYear() - 1, 12, 0)},
    ].forEach(e => this.deltaTimePreset.push(e));
    this.deltaTimePresetSelected = this.deltaTimePreset[0];
  }

  ngOnInit() {
    this.perStopDate = this.deltaTimePresetSelected.end;
    this.perStartDate = this.deltaTimePresetSelected.start;
    this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
    this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
    $('#report thead tr').clone(true).appendTo('#report thead');
    this.loadData();
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
  }

  loadData() {
    this.http.get('hr/point/hrPointageCurrentUserGet' + '?dateFrom=' + this.perStart
      + '&dateTo=' + this.perStop)
      .subscribe(
        data => {
          if (data['errorMessage']) {
            console.error(data);
          } else {
            this.data = data['pointages'];
            this.dtData = [];

            for (let i = 0; i < this.data.length; i++) {
              try {
                this.dtData.push([this.data[i].DateTime_UTC,
                  this.data[i].ParentName,
                  this.data[i].ChildName,
                  this.data[i].Source,
                ]);
              } catch (exception) {
                console.error(exception);
              }
            }
            this.DTInit();
            this.loading = false;
          }
        },
        error => console.error(error));
  }

  applyFilters() {
    this.loading = true;
    this.perStartDate = new Date(this.perStart);
    this.perStopDate = new Date(this.perStop);
    this.filterOn = true;

    this.loadData();
  }

  DTInit() {
    const currentdate = new Date();
    if (this.filterOn) {
      this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
      this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
    }

    const datetime = currentdate.toLocaleDateString('fr-CH') + ' ' + currentdate.toLocaleTimeString('fr-CH');

    let messageTop = 'Export détaillé des activités\n\n';
    messageTop += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '\n';
    if (this.filterOnlyCounted) {
      messageTop += 'Seulement les activités comptabilisées';
    }
    const messageBottom = '\n\n\n(c) D4E - Digital4Efficiency ' + currentdate.getFullYear() + '\n' + datetime;

    let messageTopExcel = 'Export détaillé des activités - ';
    messageTopExcel += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '\n';
    if (this.filterOnlyCounted) {
      messageTopExcel += ' - Seulement les activités comptabilisées';
    }
    const messageBottomExcel = '(c) D4E - Digital4Efficiency ' + currentdate.getFullYear() + ' - ' + datetime;

    let messageTopHtml = '<h3>Export détaillé des activités</h3><br/><br/>';
    messageTopHtml += 'Période ' + formatDate(this.perStart, 'dd/MM/yyyy', 'fr-CH') + ' - ' +
      formatDate(this.perStop, 'dd/MM/yyyy', 'fr-CH') + '<br/>';
    if (this.filterOnlyCounted) {
      messageTopHtml += 'Seulement les activités comptabilisées<br/>';
    }
    const messageBottomHtml = '<br/><br/>' + datetime + '<br/>&copy; D4E - Digital4Efficiency ' + currentdate.getFullYear();


    this.table = $('#report').DataTable(
      {
        destroy: true,
        orderCellsTop: true,
        'language': {
          'sProcessing': 'Traitement en cours...',
          'sSearch': 'Rechercher&nbsp;:',
          'sLengthMenu': 'Afficher _MENU_ éléments',
          'sInfo': 'Affichage des éléments _START_ &agrave; _END_ sur _TOTAL_ éléments',
          'sInfoEmpty': 'Affichage de l\'élément 0 &agrave; 0 sur 0 élément',
          'sInfoFiltered': '(filtré de _MAX_ éléments au total)',
          'sInfoPostFix': '',
          'sLoadingRecords': 'Chargement en cours...',
          'sZeroRecords': 'Aucun élément &agrave; afficher',
          'sEmptyTable': 'Aucune donnée disponible dans le tableau',
          'oPaginate': {
            'sFirst': 'Premier',
            'sPrevious': 'Précédent',
            'sNext': 'Suivant',
            'sLast': 'Dernier'
          },
          'oAria': {
            'sSortAscending': ': activer pour trier la colonne par ordre croissant',
            'sSortDescending': ': activer pour trier la colonne par ordre décroissant'
          },
          'select': {
            'rows': {
              _: '%d lignes sélectionnées',
              0: 'Aucune ligne sélectionnée',
              1: '1 ligne sélectionnée'
            }
          }
        },
        data: this.dtData,
        'columnDefs': [
          {
            'render': function (data, _, __) {
              function pad(s) {
                return (s < 5) ? '0' + s : s;
              }

              const d = new Date(data);
              return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/') + ' ' + pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds());
            },
            'targets': 0
          },
          {
            'render': function (data, _, __) {
              let source = '';
              if (data == 'W') {
                source = 'Pointage validé';
              } else if (data == 'V') {
                source = 'Pointage à valider';
              } else if (data == 'U') {
                source = 'Pointage refusé';
              } else {
                source = 'Inconnue : ' + data;
              }
              return source;
            },
            'targets': 3
          }
        ],

        'initComplete': function () {
        },
        stateSave: false,
        colReorder: false, // TODO bug refresh
        lengthMenu: [[15, 25, 50, -1], [15, 25, 50, 'Tout']],
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'copy',
            text: 'Copier les données'
          },
          {
            extend: 'csv',
            text: 'Exporter en CSV'
          },
          {
            extend: 'excel',
            text: 'Exporter vers Excel',
            messageTop: messageTopExcel,
            messageBottom: messageBottomExcel,
            sheetName: 'D4E LYA'
          },
          {
            extend: 'pdf',
            text: 'Générer un PDF',
            messageTop: messageTop,
            messageBottom: messageBottom
          },
          {
            extend: 'print',
            text: 'Imprimer',
            messageTop: messageTopHtml,
            messageBottom: messageBottomHtml
          },
          'pageLength'
        ]
      });
    const mytab = this.table;

    this.filteredTable = this.table.rows({search: 'applied'});
    if (this.filterOnlyCounted) {
      this.table
        .search(' ');
      this.table
        .search('validé|Pointage', true, false, false) // search( input [, regex[ , smart[ , caseInsen ]]] )
        .draw();
      const element = $('input[placeholder="Source"]');
      element[0].value = '';
      element.prop('disabled', true);

    } else {
      this.table
        .column(0)
        .search('')
        .draw();
    }

    this.restoreBSClasses();

    $('#report thead tr:eq(1) th').each(function (i) {
      $('input', this).on('keyup change clear', function () {
        if (mytab.column(i).search() !== this.value) {
          mytab
            .column(i)
            .search(this.value)
            .draw();
        }
      });
    });
    this.table.on('draw', function () {
      $('.paginate_button').addClass('btn').addClass('btn-primary').removeClass('paginate_button');
      $('.current').removeClass('btn-primary');
    });
  }

  restoreBSClasses() {
    $('.dt-button').addClass('btn').addClass('btn-primary').removeClass('dt-button');
    $('.paginate_button').addClass('btn').addClass('btn-primary').removeClass('paginate_button');
    $('.current').removeClass('btn-primary');
  }

  SelectedTimeChange() {
    this.perStopDate = this.deltaTimePresetSelected.end;
    this.perStartDate = this.deltaTimePresetSelected.start;
    this.perStop = formatDate(this.perStopDate, 'yyyy-MM-dd', 'fr');
    this.perStart = formatDate(this.perStartDate, 'yyyy-MM-dd', 'fr');
  }

}
