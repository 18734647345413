import {AfterViewInit, Component} from '@angular/core';
import {User} from './_models';
import {AuthenticationService} from './_services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit {
    currentUser: User;


    constructor(public authService: AuthenticationService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            authService.user = this.currentUser;
        }
    }

    ngAfterViewInit() {
    }
}
