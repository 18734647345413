import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {User} from '../_models/user';
import {AuthenticationService} from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthenticationService) {
    }

    user: User;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            this.user = JSON.parse(localStorage.getItem('currentUser'));

            return this.authService.checkrenew(this.user).map(
                data => {
                    // console.log("checkrenew:" + JSON.stringify(data));

                    if (data) {
                        return true;
                    } else {
                        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                        return false;

                    }

                },
                _ => {
                    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                    return false;
                });
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
