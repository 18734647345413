import {Injectable} from '@angular/core';

@Injectable()
export class DatesHelper {


    getWeek(val: Date) {

        // Create a copy of this date object
        const target: any = new Date(val);

        // ISO week date weeks start on monday, so correct the day number
        const dayNr = (val.getDay() + 6) % 7;

        // Set the target to the thursday of this week so the
        // target date is in the right year
        target.setDate(target.getDate() - dayNr + 3);

        // ISO 8601 states that week 1 is the week with january 4th in it
        const jan4: any = new Date(target.getFullYear(), 0, 4);

        // Number of days between target date and january 4th
        const dayDiff = (target - jan4) / 86400000;

        if (new Date(target.getFullYear(), 0, 1).getDay() < 5) {
            // Calculate week number: Week 1 (january 4th) plus the
            // number of weeks between target date and january 4th
            return 1 + Math.ceil(dayDiff / 7);
        } else {  // jan 4th is on the next week (so next week is week 1)
            return Math.ceil(dayDiff / 7);
        }
    }

    getDateRangeOfWeek(weekNo: number, selectedDate: Date) {
        const d1: Date = new Date();
        d1.setFullYear(selectedDate.getFullYear());
        const numOfdaysPastSinceLastMonday: number = d1.getDay() - 1;
        d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        const weekNoToday = this.getWeek(d1);
        const weeksInTheFuture = weekNo - weekNoToday;
        d1.setDate(d1.getDate() + 7 * weeksInTheFuture);
        const rangeIsFrom = (d1.getMonth() + 1) + '/' + d1.getDate() + '/' + d1.getFullYear();
        d1.setDate(d1.getDate() + 6);
        const rangeIsTo = (d1.getMonth() + 1) + '/' + d1.getDate() + '/' + d1.getFullYear();
        return rangeIsFrom + ' to ' + rangeIsTo;
    }

    // _getDateRangeOfWeekPrecalc(weekNo:number){
    //     var d1:Date = new Date();
    //     var numOfdaysPastSinceLastMonday:number = d1.getDay()- 1;
    //     d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    //     var weekNoToday = this.getWeek(d1);
    //     var weeksInTheFuture = weekNo - weekNoToday;
    //     d1.setDate(d1.getDate() + 7 * weeksInTheFuture );

    //     return d1;
    // }

    // TODO ANNEE
    getDateRangeOfWeekFrom(weekNo: number, selectedDate: Date) {
        const d1: Date = new Date();
        d1.setFullYear(selectedDate.getFullYear());
        const numOfdaysPastSinceLastMonday: number = d1.getDay() - 1;
        d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        const weekNoToday = this.getWeek(d1);
        const weeksInTheFuture = weekNo - weekNoToday;
        d1.setDate(d1.getDate() + 7 * weeksInTheFuture);
        // var rangeIsFrom = (d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();
        // d1.setDate(d1.getDate() + 6);
        // var rangeIsTo = (d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
        return d1;
    }

    // TODO ANNEE
    getDateRangeOfWeekTo(weekNo: number, selectedDate: Date) {
        const d1: Date = this.getDateRangeOfWeekFrom(weekNo, selectedDate);
        // var numOfdaysPastSinceLastMonday:number = d1.getDay()- 1;
        // d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        // var weekNoToday = this.getWeek(d1);
        // var weeksInTheFuture = weekNo - weekNoToday;
        // d1.setDate(d1.getDate() + 7 * weeksInTheFuture );
        d1.setDate(d1.getDate() + 6);
        // var rangeIsFrom = (d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();
        // d1.setDate(d1.getDate() + 6);
        // var rangeIsTo = (d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
        return d1;
    }

    getFirstDayOfMonth(dDate: Date) {
        return new Date(dDate.getFullYear(), dDate.getMonth());
    }

    getLastDayOfMonth(dDate: Date) {
        const rDate = this.getFirstDayOfMonth(dDate);  // new Date(dDate.getFullYear(), dDate.getMonth());
        rDate.setMonth(rDate.getMonth() + 1);
        rDate.setDate(rDate.getDate() - 1);

        return rDate;
    }
}
