import {formatDate} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class HrService {
    constructor(private http: HttpClient) {
    }

    /*
      $$\                  $$$$$$\                                 $$\                         $$$$$$$\                      $$\   $$\                                $$$$$$\             $$\
      $$ |                $$  __$$\                                $$ |                        $$  __$$\                     $$ |  $$ |                              $$  __$$\            $$ |
      $$$$$$$\   $$$$$$\  $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\  $$ |  $$ | $$$$$$\  $$\   $$\ $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\  $$ /  \__| $$$$$$\ $$$$$$\
      $$  __$$\ $$  __$$\ $$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\ $$ |  $$ | \____$$\ $$ |  $$ |$$ |  $$ |$$  _____|$$  __$$\ $$  __$$\ $$ |$$$$\ $$  __$$\\_$$  _|
      $$ |  $$ |$$ |  \__|$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|$$ |  $$ | $$$$$$$ |$$ |  $$ |$$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|$$ |\_$$ |$$$$$$$$ | $$ |
      $$ |  $$ |$$ |      $$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |      $$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$ | \____$$\ $$   ____|$$ |      $$ |  $$ |$$   ____| $$ |$$\
      $$ |  $$ |$$ |      \$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |\$$$$$$$ |\$$$$$$$ |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      \$$$$$$  |\$$$$$$$\  \$$$$  |
      \__|  \__|\__|       \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|      \_______/  \_______| \____$$ | \______/ \_______/  \_______|\__|       \______/  \_______|  \____/
                                                                                                                   $$\   $$ |
                                                                                                                   \$$$$$$  |
                                                                                                                    \______/
    */

    hrCounterDayUserGet(userId: string, dateRangeFrom: Date, dateRangeTo: Date) {

        const parameters = '&dateFrom=' + formatDate(dateRangeFrom, 'yyyy-MM-dd', 'fr') + '&dateTo=' + formatDate(dateRangeTo, 'yyyy-MM-dd', 'fr');

        return this.http.get('hr/counter/hrCounterDayUserGet?userId=' + userId + parameters).map(
            data => {
                // console.log('HrService-hrCounterDayUserGet returned body was: ' + JSON.stringify(data));
                if (data['error']) {
                    // TODO
                    // this.model.error = data['error']['message'];
                }
                // this.hrCompteursDayOfWeek = data['counterday'];
                return data;

                // console.log(this.myMembers);

                // this.loading = false;
            });
    }

    hrCounterDayGet(dateRangeFrom: Date, dateRangeTo: Date) {

        return this.http.get('hr/counter/hrCounterDayGet' + '?dateFrom=' + formatDate(dateRangeFrom, 'yyyy-MM-dd', 'fr')
            + '&dateTo=' + formatDate(dateRangeTo, 'yyyy-MM-dd', 'fr')).map(
            data => {
                // console.log('HrService-hrCounterDayGet returned body was: ' + JSON.stringify(data));
                if (data['error']) {
                    // TODO
                    // this.model.error = data['error']['message'];
                }
                // this.hrCompteursDayOfWeek = data['counterday'];
                return data;

                // console.log(this.myMembers);
            });
    }

    /*
      $$\                  $$$$$$\                                 $$\                         $$$$$$$\                      $$\   $$\                                $$$$$$\             $$\      $$$$$$\                                          $$$$$$$\
      $$ |                $$  __$$\                                $$ |                        $$  __$$\                     $$ |  $$ |                              $$  __$$\            $$ |    $$  __$$\                                         $$  __$$\
      $$$$$$$\   $$$$$$\  $$ /  \__| $$$$$$\  $$\   $$\ $$$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\  $$ |  $$ | $$$$$$\  $$\   $$\ $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\  $$ /  \__| $$$$$$\ $$$$$$\   $$ /  \__| $$$$$$\   $$$$$$\  $$\   $$\  $$$$$$\  $$ |  $$ |$$\   $$\
      $$  __$$\ $$  __$$\ $$ |      $$  __$$\ $$ |  $$ |$$  __$$\\_$$  _|  $$  __$$\ $$  __$$\ $$ |  $$ | \____$$\ $$ |  $$ |$$ |  $$ |$$  _____|$$  __$$\ $$  __$$\ $$ |$$$$\ $$  __$$\\_$$  _|  $$ |$$$$\ $$  __$$\ $$  __$$\ $$ |  $$ |$$  __$$\ $$$$$$$\ |$$ |  $$ |
      $$ |  $$ |$$ |  \__|$$ |      $$ /  $$ |$$ |  $$ |$$ |  $$ | $$ |    $$$$$$$$ |$$ |  \__|$$ |  $$ | $$$$$$$ |$$ |  $$ |$$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|$$ |\_$$ |$$$$$$$$ | $$ |    $$ |\_$$ |$$ |  \__|$$ /  $$ |$$ |  $$ |$$ /  $$ |$$  __$$\ $$ |  $$ |
      $$ |  $$ |$$ |      $$ |  $$\ $$ |  $$ |$$ |  $$ |$$ |  $$ | $$ |$$\ $$   ____|$$ |      $$ |  $$ |$$  __$$ |$$ |  $$ |$$ |  $$ | \____$$\ $$   ____|$$ |      $$ |  $$ |$$   ____| $$ |$$\ $$ |  $$ |$$ |      $$ |  $$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |
      $$ |  $$ |$$ |      \$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ | \$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |\$$$$$$$ |\$$$$$$$ |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      \$$$$$$  |\$$$$$$$\  \$$$$  |\$$$$$$  |$$ |      \$$$$$$  |\$$$$$$  |$$$$$$$  |$$$$$$$  |\$$$$$$$ |
      \__|  \__|\__|       \______/  \______/  \______/ \__|  \__|  \____/  \_______|\__|      \_______/  \_______| \____$$ | \______/ \_______/  \_______|\__|       \______/  \_______|  \____/  \______/ \__|       \______/  \______/ $$  ____/ \_______/  \____$$ |
                                                                                                                   $$\   $$ |                                                                                                             $$ |                $$\   $$ |
                                                                                                                   \$$$$$$  |                                                                                                             $$ |                \$$$$$$  |
                                                                                                                    \______/                                                                                                              \__|                 \______/
    */

    hrCounterDayUserGetGroupBy(userId: string, dateRangeFrom: Date, dateRangeTo: Date, withSource: boolean) {

        let parameters = '&dateFrom=' + formatDate(dateRangeFrom, 'yyyy-MM-dd', 'fr') + '&dateTo=' + formatDate(dateRangeTo, 'yyyy-MM-dd', 'fr');

        if (withSource) {
            parameters += '&withSource=yes';
        }

        return this.http.get('hr/counter/hrCounterDayUserGetGroupBy?userId=' + userId + parameters).map(
            data => {
                // console.log('HrService-hrCounterDayUserGetGroupBy returned body was: ' + JSON.stringify(data));
                if (data['error']) {
                    // TODO
                    // this.model.error = data['error']['message'];
                }
                // this.hrCompteursDayOfWeek = data['counterday'];
                return data;
            });
    }
}
