import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Swal from '../../assets/js/plugins/sweetalert2';
import {AuthenticationService} from '../_services';
import {ProjectService} from "../_services/project.service";
import {Project} from "../_models/project";

declare var $: any;

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit, AfterViewInit {

  id: number;
  project: any;

  loading = true;
  loadingTeams = true;
  loadingUsers = true;
  sousProjectNameCreate: any;

  hrPointagesProjectsAll: any;
  hrPointagesProjects2: any;
  AllTeams: any;
  AllUsers: any;

  cfgProjetExternalId: any;
  cfgHoursDivided = false;

  hrTeams = [{'Id': 1, 'Name': 'Tous'}];
  public projectLoading = true;
  public subProjectLoading = true;

  constructor(
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService) {
    this.project = {};

    const ProjetExternalIdConfig = this.authenticationService.entreprise.configs.filter(e => e.ConfigKey === 'ProjetExternalId')[0];
    if (ProjetExternalIdConfig != undefined && ProjetExternalIdConfig != 0) {
      this.cfgProjetExternalId = ProjetExternalIdConfig.ConfigValue;
    } else {
      this.cfgProjetExternalId = 0;
    } // Par défaut : Pas de Ref externe sur les projets
  }

  ngOnInit() {
    // this.loading = true;
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getProject();
      this.getTeams();
      this.getUsers();
    });
    this.cfgHoursDivided = localStorage.getItem('cfgHoursDivided') == '1';
  }

  /*
                         $$\     $$$$$$$\                                                $$\
                         $$ |    $$  __$$\                                               $$ |
     $$$$$$\   $$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\ $$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$$$$$$  |$$  __$$\ $$  __$$\ \__|$$  __$$\ $$  _____|\_$$  _|
    $$ /  $$ |$$$$$$$$ | $$ |    $$  ____/ $$ |  \__|$$ /  $$ |$$\ $$$$$$$$ |$$ /        $$ |
    $$ |  $$ |$$   ____| $$ |$$\ $$ |      $$ |      $$ |  $$ |$$ |$$   ____|$$ |        $$ |$$\
    \$$$$$$$ |\$$$$$$$\  \$$$$  |$$ |      $$ |      \$$$$$$  |$$ |\$$$$$$$\ \$$$$$$$\   \$$$$  |
     \____$$ | \_______|  \____/ \__|      \__|       \______/ $$ | \_______| \_______|   \____/
    $$\   $$ |                                           $$\   $$ |
    \$$$$$$  |                                           \$$$$$$  |
     \______/                                             \______/
  */

  getProject(): void {
    this.projectLoading = true;
    this.subProjectLoading = true;
    this.projectService.getInfo(this.id).subscribe(
      data => {
        this.project = data;
        this.projectLoading = false;
      },
      err => {
        // console.error(err);
        // location.reload(); // recharge la page car surement pb de login -> login
      });
    const project = new Project();
    project.id = this.id;
    this.projectService.getSubProject(project).subscribe(
      data => {
        this.hrPointagesProjects2 = data;
        this.subProjectLoading = false;
        let i = -1;
        this.hrPointagesProjects2.filter(p => p.Teams != null).forEach(p => {
          p['teamList'] = p.Teams.split(',').map(s => {
            i++;
            return {
              'Nom': s,
              'id': p.TeamsId.split(',')[i]
            };
          });
        });
      }
    );
  }

  /*
                         $$\  $$$$$$$$\
                         $$ | \__$$  __|
     $$$$$$\   $$$$$$\ $$$$$$\   $$ | $$$$$$\   $$$$$$\  $$$$$$\$$$$\   $$$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$ |$$  __$$\  \____$$\ $$  _$$  _$$\ $$  _____|
    $$ /  $$ |$$$$$$$$ | $$ |    $$ |$$$$$$$$ | $$$$$$$ |$$ / $$ / $$ |\$$$$$$\
    $$ |  $$ |$$   ____| $$ |$$\ $$ |$$   ____|$$  __$$ |$$ | $$ | $$ | \____$$\
    \$$$$$$$ |\$$$$$$$\  \$$$$  |$$ |\$$$$$$$\ \$$$$$$$ |$$ | $$ | $$ |$$$$$$$  |
     \____$$ | \_______|  \____/ \__| \_______| \_______|\__| \__| \__|\_______/
    $$\   $$ |
    \$$$$$$  |
     \______/
  */

  getTeams(): void {
    this.loadingTeams = true;
    this.http.get('hr/team/hrTeamsGetForProject?ProjectId=' + this.id).subscribe(
      data => {
        // console.log('ProjectEditComponent-Backend-hrTeamsGetForProject returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
        }
        this.AllTeams = data['teams'].filter(t => t.IsArchived == 0);
        this.loadingTeams = false;
      },
      (err: HttpErrorResponse) => {
        console.error(err);
        location.reload();
      });
  }

  /*
                         $$\     $$\   $$\
                         $$ |    $$ |  $$ |
     $$$$$$\   $$$$$$\ $$$$$$\   $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\   $$$$$$$\
    $$  __$$\ $$  __$$\\_$$  _|  $$ |  $$ |$$  _____|$$  __$$\ $$  __$$\ $$  _____|
    $$ /  $$ |$$$$$$$$ | $$ |    $$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|\$$$$$$\
    $$ |  $$ |$$   ____| $$ |$$\ $$ |  $$ | \____$$\ $$   ____|$$ |       \____$$\
    \$$$$$$$ |\$$$$$$$\  \$$$$  |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |
     \____$$ | \_______|  \____/  \______/ \_______/  \_______|\__|      \_______/
    $$\   $$ |
    \$$$$$$  |
     \______/
  */

  getUsers(): void {
    this.loadingUsers = true;
    this.http.get('hr/project/hrUserGetForProject?ProjectId=' + this.id).subscribe(
      data => {
        // console.log('ProjectEditComponent-Backend-hrUserGetForProject returned body was: ' + JSON.stringify(data));
        if (data['error']) {
          // TODO
          // this.model.error = data['error']['message'];
        }
        this.AllUsers = data['users'];
        this.loadingUsers = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      });
  }

  ngAfterViewInit(): void {
    this.loading = false;
  }

  SsProjectCreate(): void {
    // this.loading = true;
    const body = {
      'project1': this.id,
      'ProjectName': this.sousProjectNameCreate
    };
    // console.log('body: ' + JSON.stringify(body));

    this.http.post('hr/point/hrPointageProjectsCreate', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        $('#createSsProjectModal').modal('hide');
        this.getProject();
        this.sousProjectNameCreate = '';
        // this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
        }
      }
    );
    // this.loading = false;
  }

  /*
                              $$\            $$\                $$$$$$\  $$\ $$\                     $$$$$$$\  $$\           $$\
                              $$ |           $$ |              $$  __$$\ $$ |$$ |                    $$  __$$\ \__|          $$ |
    $$\   $$\  $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\  $$ /  $$ |$$ |$$ | $$$$$$\   $$$$$$$\ $$ |  $$ |$$\  $$$$$$\  $$ | $$$$$$\   $$$$$$\
    $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\ $$$$$$$$ |$$ |$$ |$$  __$$\ $$  _____|$$ |  $$ |$$ | \____$$\ $$ |$$  __$$\ $$  __$$\
    $$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$  __$$ |$$ |$$ |$$ /  $$ |$$ /      $$ |  $$ |$$ | $$$$$$$ |$$ |$$ /  $$ |$$ /  $$ |
    $$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |  $$ |$$ |$$ |$$ |  $$ |$$ |      $$ |  $$ |$$ |$$  __$$ |$$ |$$ |  $$ |$$ |  $$ |
    \$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\ $$ |  $$ |$$ |$$ |\$$$$$$  |\$$$$$$$\ $$$$$$$  |$$ |\$$$$$$$ |$$ |\$$$$$$  |\$$$$$$$ |
     \______/ $$  ____/  \_______| \_______|  \____/  \_______|\__|  \__|\__|\__| \______/  \_______|\_______/ \__| \_______|\__| \______/  \____$$ |
              $$ |                                                                                                                         $$\   $$ |
              $$ |                                                                                                                         \$$$$$$  |
              \__|                                                                                                                          \______/
  */

  updateAllocDialog() {
    Swal.fire({
      title: 'Indiquer la durée prévue pour ce projet (en jours)',
      input: 'number',
      inputAttributes: {
        step: '0.01'
      },
      inputValue: this.project.StatAllocated / 8,
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Attribuer',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: (val) => {
        const body = {
          ProjectId: this.project.id,
          StatAllocated: val * 8
        };
        this.http.post('hr/project/hrProjectStatAllocatedUpdate', body).subscribe(
          data => {
            this.getProject();
            // this.validCountersAll();
            Swal.fire(
              'Enregistré !',
              'La durée prévue du projet est enregistrée !',
              'success'
            );
            return data;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);
              Swal.showValidationMessage(
                `Request failed: ${err}`
              );
              location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
              location.reload(); // recharge la page car surement pb de login -> login
            }
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  updateAllocDialogHours() {
    Swal.fire({
      title: 'Indiquer la durée prévue pour ce projet (en heures)',
      input: 'number',
      inputAttributes: {
        step: '0.25'
      },
      inputValue: this.project.StatAllocated * 1,
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Attribuer',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: (val) => {
        const body = {
          ProjectId: this.project.id,
          StatAllocated: val
        };
        this.http.post('hr/project/hrProjectStatAllocatedUpdate', body).subscribe(
          data => {
            this.getProject();
            // this.validCountersAll();
            Swal.fire(
              'Enregistré !',
              'La durée prévue du projet est enregistrée !',
              'success'
            );
            return data;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);
              Swal.showValidationMessage(
                `Request failed: ${err}`
              );
              location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
              location.reload(); // recharge la page car surement pb de login -> login
            }
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  /*
                              $$\            $$\               $$\   $$\                                   $$$$$$$\  $$\           $$\
                              $$ |           $$ |              $$$\  $$ |                                  $$  __$$\ \__|          $$ |
    $$\   $$\  $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\  $$$$\ $$ | $$$$$$\  $$$$$$\$$$$\   $$$$$$\  $$ |  $$ |$$\  $$$$$$\  $$ | $$$$$$\   $$$$$$\
    $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\ $$ $$\$$ | \____$$\ $$  _$$  _$$\ $$  __$$\ $$ |  $$ |$$ | \____$$\ $$ |$$  __$$\ $$  __$$\
    $$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$ \$$$$ | $$$$$$$ |$$ / $$ / $$ |$$$$$$$$ |$$ |  $$ |$$ | $$$$$$$ |$$ |$$ /  $$ |$$ /  $$ |
    $$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |\$$$ |$$  __$$ |$$ | $$ | $$ |$$   ____|$$ |  $$ |$$ |$$  __$$ |$$ |$$ |  $$ |$$ |  $$ |
    \$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\ $$ | \$$ |\$$$$$$$ |$$ | $$ | $$ |\$$$$$$$\ $$$$$$$  |$$ |\$$$$$$$ |$$ |\$$$$$$  |\$$$$$$$ |
     \______/ $$  ____/  \_______| \_______|  \____/  \_______|\__|  \__| \_______|\__| \__| \__| \_______|\_______/ \__| \_______|\__| \______/  \____$$ |
              $$ |                                                                                                                               $$\   $$ |
              $$ |                                                                                                                               \$$$$$$  |
              \__|                                                                                                                                \______/
  */

  updateNameDialog() {
    event.preventDefault();

    Swal.fire({
      title: 'Nouveau nom du projet',
      input: 'text',
      inputValue: this.project.Project,
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Renommer',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: (val) => {
        const body = {
          ProjectId: this.project.id,
          NewName: val
        };
        this.http.post('hr/project/hrProjectNameUpdate', body).subscribe(
          data => {
            this.getProject();
            Swal.fire(
              'Enregistré !',
              'Le nouveau nom du projet est enregistré !',
              'success'
            );
            return data;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);
              Swal.showValidationMessage(
                `Request failed: ${err}`
              );
              location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
              location.reload(); // recharge la page car surement pb de login -> login
            }
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  /*
                              $$\            $$\               $$$$$$$$\             $$\                                             $$\ $$$$$$\      $$\ $$$$$$$\  $$\           $$\
                              $$ |           $$ |              $$  _____|            $$ |                                            $$ |\_$$  _|     $$ |$$  __$$\ \__|          $$ |
    $$\   $$\  $$$$$$\   $$$$$$$ | $$$$$$\ $$$$$$\    $$$$$$\  $$ |      $$\   $$\ $$$$$$\    $$$$$$\   $$$$$$\  $$$$$$$\   $$$$$$\  $$ |  $$ |  $$$$$$$ |$$ |  $$ |$$\  $$$$$$\  $$ | $$$$$$\   $$$$$$\
    $$ |  $$ |$$  __$$\ $$  __$$ | \____$$\\_$$  _|  $$  __$$\ $$$$$\    \$$\ $$  |\_$$  _|  $$  __$$\ $$  __$$\ $$  __$$\  \____$$\ $$ |  $$ | $$  __$$ |$$ |  $$ |$$ | \____$$\ $$ |$$  __$$\ $$  __$$\
    $$ |  $$ |$$ /  $$ |$$ /  $$ | $$$$$$$ | $$ |    $$$$$$$$ |$$  __|    \$$$$  /   $$ |    $$$$$$$$ |$$ |  \__|$$ |  $$ | $$$$$$$ |$$ |  $$ | $$ /  $$ |$$ |  $$ |$$ | $$$$$$$ |$$ |$$ /  $$ |$$ /  $$ |
    $$ |  $$ |$$ |  $$ |$$ |  $$ |$$  __$$ | $$ |$$\ $$   ____|$$ |       $$  $$<    $$ |$$\ $$   ____|$$ |      $$ |  $$ |$$  __$$ |$$ |  $$ | $$ |  $$ |$$ |  $$ |$$ |$$  __$$ |$$ |$$ |  $$ |$$ |  $$ |
    \$$$$$$  |$$$$$$$  |\$$$$$$$ |\$$$$$$$ | \$$$$  |\$$$$$$$\ $$$$$$$$\ $$  /\$$\   \$$$$  |\$$$$$$$\ $$ |      $$ |  $$ |\$$$$$$$ |$$ |$$$$$$\\$$$$$$$ |$$$$$$$  |$$ |\$$$$$$$ |$$ |\$$$$$$  |\$$$$$$$ |
     \______/ $$  ____/  \_______| \_______|  \____/  \_______|\________|\__/  \__|   \____/  \_______|\__|      \__|  \__| \_______|\__|\______|\_______|\_______/ \__| \_______|\__| \______/  \____$$ |
              $$ |                                                                                                                                                                              $$\   $$ |
              $$ |                                                                                                                                                                              \$$$$$$  |
              \__|                                                                                                                                                                               \______/
  */

  updateExternalIdDialog() {
    event.preventDefault();

    if (this.project.ExternalId == null) {
      this.project.ExternalId = '';
    }

    Swal.fire({
      title: 'R&eacute;f&eacute;rence externe du projet',
      input: 'text',
      inputValue: this.project.ExternalId,
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'btn',
      confirmButtonClass: 'btn btn-success',
      confirmButtonText: 'Enregistrer',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: (val) => {
        const body = {
          ProjectId: this.project.id,
          ExternalId: val
        };
        this.http.post('hr/project/hrProjectExtIdUpdate', body).subscribe(
          data => {
            this.getProject();
            // if (result.value) {
            Swal.fire(
              'Enregistr&eacute; !',
              'La r&eacute;f&eacute;rence externe du projet est enregistr&eacute;e !',
              'success'
            );
            return data;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              // console.log('An error occurred:', err.error.message);

              Swal.showValidationMessage(
                `Request failed: ${err}`
              );
              location.reload(); // recharge la page car surement pb de login -> login
            } else {
              // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
              // console.log(`err: ${JSON.stringify(err)}`);
              // console.log(`err: ${err}`);
              location.reload(); // recharge la page car surement pb de login -> login
            }
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  /*
    $$\                 $$$$$$$\                                                $$\      $$$$$$\                      $$\       $$\                      $$$$$$$\            $$\
    $$ |                $$  __$$\                                               $$ |    $$  __$$\                     $$ |      \__|                     $$  __$$\           $$ |
    $$$$$$$\   $$$$$$\  $$ |  $$ | $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\ $$$$$$\   $$ /  $$ | $$$$$$\   $$$$$$$\ $$$$$$$\  $$\ $$\    $$\  $$$$$$\  $$ |  $$ | $$$$$$\  $$ |
    $$  __$$\ $$  __$$\ $$$$$$$  |$$  __$$\ $$  __$$\ \__|$$  __$$\ $$  _____|\_$$  _|  $$$$$$$$ |$$  __$$\ $$  _____|$$  __$$\ $$ |\$$\  $$  |$$  __$$\ $$ |  $$ |$$  __$$\ $$ |
    $$ |  $$ |$$ |  \__|$$  ____/ $$ |  \__|$$ /  $$ |$$\ $$$$$$$$ |$$ /        $$ |    $$  __$$ |$$ |  \__|$$ /      $$ |  $$ |$$ | \$$\$$  / $$$$$$$$ |$$ |  $$ |$$$$$$$$ |$$ |
    $$ |  $$ |$$ |      $$ |      $$ |      $$ |  $$ |$$ |$$   ____|$$ |        $$ |$$\ $$ |  $$ |$$ |      $$ |      $$ |  $$ |$$ |  \$$$  /  $$   ____|$$ |  $$ |$$   ____|$$ |
    $$ |  $$ |$$ |      $$ |      $$ |      \$$$$$$  |$$ |\$$$$$$$\ \$$$$$$$\   \$$$$  |$$ |  $$ |$$ |      \$$$$$$$\ $$ |  $$ |$$ |   \$  /   \$$$$$$$\ $$$$$$$  |\$$$$$$$\ $$ |
    \__|  \__|\__|      \__|      \__|       \______/ $$ | \_______| \_______|   \____/ \__|  \__|\__|       \_______|\__|  \__|\__|    \_/     \_______|\_______/  \_______|\__|
                                                $$\   $$ |
                                                \$$$$$$  |
                                                 \______/
  */

  hrProjectArchiveDel(projectId) {
    // this.loading = true;
    const body = {
      'projectId': projectId
    };
    // console.log('body: ' + JSON.stringify(body));

    Swal.fire({
      title: 'Supprimer/Archiver ?',
      text: 'Voulez-vous supprimer (ou archiver) ce projet ? Pour qu\'il soit effectivement supprimé, il ne doit plus contenir de sous-projet, et aucune activité ni pointage ne doit avoir eu lieu dessus.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Supprimer/Archiver !',
      cancelButtonText: 'Non, Annuler',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.http.post('hr/point/hrPointageProjectsArchiveDelete', body).subscribe(
          data => {
            // console.log('Backend returned body was: ' + JSON.stringify(data));
            // $('#createProjectModal').modal('hide');
            this.getProject();
            // this.projectNameCreate = "";
            // this.loading = false;

            // var jsonData = JSON.stringify(data);

            if (data[0].deleted_row_count == 1) {
              Swal.fire(
                'Supprimé !',
                'Le sous-projet était vide, et a donc été supprimé.',
                'success'
              );
            } else if (data[0].updated_row_count == 1) {
              Swal.fire(
                'Archivé !',
                'Le sous-projet n\'était pas vide ou a été utilisé, et a donc été simplement archivé.',
                'success'
              );
            } else {
              Swal.fire(
                'Utilisé !',
                'Le projet n\'était pas vide ou a été utilisé, et n\'a donc pas été supprimé.',
                'error'
              );
            }
          },
          (err: HttpErrorResponse) => {
            console.error(err);
            location.reload(); // recharge la page car surement pb de login -> login
          }
        );
      } else {
        // this.loading = false;
      }
    });
  }

  hrProjectUnArchive(projectId) {
    // this.loading = true;
    const body = {
      'projectId': projectId
    };
    this.http.post('hr/point/hrPointageProjectsUnArchive', body).subscribe(
      data => {
        // this.loading = false;
        if (data[0].updated_row_count > 0) {
          Swal.fire(
            'Restauré !',
            'Le sous-projet a été restauré.',
            'success'
          ).then((_) => location.reload());
        }
      },
      (err: HttpErrorResponse) => {
        console.error(err);
        location.reload(); // recharge la page car surement pb de login -> login
      }
    );
  }

  /*
    $$$$$$$\                                                $$\     $$$$$$\        $$$$$$$$\
    $$  __$$\                                               $$ |    \_$$  _|       \__$$  __|
    $$ |  $$ | $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\ $$$$$$\     $$ |  $$$$$$$\  $$ | $$$$$$\   $$$$$$\  $$$$$$\$$$$\
    $$$$$$$  |$$  __$$\ $$  __$$\ \__|$$  __$$\ $$  _____|\_$$  _|    $$ |  $$  __$$\ $$ |$$  __$$\  \____$$\ $$  _$$  _$$\
    $$  ____/ $$ |  \__|$$ /  $$ |$$\ $$$$$$$$ |$$ /        $$ |      $$ |  $$ |  $$ |$$ |$$$$$$$$ | $$$$$$$ |$$ / $$ / $$ |
    $$ |      $$ |      $$ |  $$ |$$ |$$   ____|$$ |        $$ |$$\   $$ |  $$ |  $$ |$$ |$$   ____|$$  __$$ |$$ | $$ | $$ |
    $$ |      $$ |      \$$$$$$  |$$ |\$$$$$$$\ \$$$$$$$\   \$$$$  |$$$$$$\ $$ |  $$ |$$ |\$$$$$$$\ \$$$$$$$ |$$ | $$ | $$ |
    \__|      \__|       \______/ $$ | \_______| \_______|   \____/ \______|\__|  \__|\__| \_______| \_______|\__| \__| \__|
                            $$\   $$ |
                            \$$$$$$  |
                             \______/
  */

  ProjectInTeam(teamid, oldState) {
    this.loadingTeams = true;
    // alert('InTeam ' + projectid +  " : " + oldState);
    const body = {
      'ProjectId': this.id,
      'TeamId': teamid,
      'Add': oldState == null ? 1 : 0
    };
    this.loadingTeams = true;
    this.http.post('hr/project/hrProjectInTeam', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // $('#createProjectModal').modal('hide');
        this.getTeams();
        this.loadingTeams = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }


  /*
    $$$$$$$\                                                $$\     $$$$$$\           $$\   $$\
    $$  __$$\                                               $$ |    \_$$  _|          $$ |  $$ |
    $$ |  $$ | $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\ $$$$$$\     $$ |  $$$$$$$\  $$ |  $$ | $$$$$$$\  $$$$$$\   $$$$$$\
    $$$$$$$  |$$  __$$\ $$  __$$\ \__|$$  __$$\ $$  _____|\_$$  _|    $$ |  $$  __$$\ $$ |  $$ |$$  _____|$$  __$$\ $$  __$$\
    $$  ____/ $$ |  \__|$$ /  $$ |$$\ $$$$$$$$ |$$ /        $$ |      $$ |  $$ |  $$ |$$ |  $$ |\$$$$$$\  $$$$$$$$ |$$ |  \__|
    $$ |      $$ |      $$ |  $$ |$$ |$$   ____|$$ |        $$ |$$\   $$ |  $$ |  $$ |$$ |  $$ | \____$$\ $$   ____|$$ |
    $$ |      $$ |      \$$$$$$  |$$ |\$$$$$$$\ \$$$$$$$\   \$$$$  |$$$$$$\ $$ |  $$ |\$$$$$$  |$$$$$$$  |\$$$$$$$\ $$ |
    \__|      \__|       \______/ $$ | \_______| \_______|   \____/ \______|\__|  \__| \______/ \_______/  \_______|\__|
                            $$\   $$ |
                            \$$$$$$  |
                             \______/
  */

  ProjectInUser(userid, oldState) {
    this.loadingUsers = true;
    // alert('InTeam ' + projectid +  " : " + oldState);

    const body = {
      'ProjectId': this.id,
      'UserId': userid,
      'Add': oldState == null ? 1 : 0
    };

    this.http.post('hr/project/hrProjectInUser', body).subscribe(
      _ => {
        // console.log('Backend returned body was: ' + JSON.stringify(data));
        // $('#createProjectModal').modal('hide');
        this.getUsers();
        // this.projectNameCreate = "";
        this.loadingUsers = false;

        // var jsonData = JSON.stringify(data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('An error occurred:', err.error.message);
          location.reload(); // recharge la page car surement pb de login -> login
        } else {
          // console.log(`Backend returned code ${err.status}, body was: ${JSON.stringify(err.error)}`);
          // console.log(`err: ${JSON.stringify(err)}`);
          // console.log(`err: ${err}`);
          location.reload(); // recharge la page car surement pb de login -> login
        }
      }
    );
  }

  cfgHoursDividedToggle() {
    this.cfgHoursDivided = !this.cfgHoursDivided;
    localStorage.setItem('cfgHoursDivided', this.cfgHoursDivided ? '1' : '0');
    // console.log('cfgHoursDivided:', this.cfgHoursDivided);
  }

  autoFocus(element: string) {
    setTimeout(() => document.getElementById(element).focus({preventScroll: false}), 500);
  }
}
